import { Autocomplete, TextField, Checkbox, Typography } from '@mui/material';

interface SelectorProps {
  id: string;
  label: string;
  width?: number;
  options: any;
  isRequired?: boolean;
  isError?: boolean;
  selectedValues?: string[];
  disabled?: boolean;
  maxSelections?: number;
  handleChange: (id: string, value: any) => void;
}

const MAX_STRING_LENGTH = 10;

export const renderInputTags = (value: any[]) => {
  const trimmedString = (value[0].length > 10) ? `${value[0].substring(0, MAX_STRING_LENGTH)}...` : value[0];
  if (value.length > 1) {
    return <Typography sx={{ fontSize: '0.62rem'}}>{`${trimmedString} (+${value.length - 1})`}</Typography>
  } else {
    return <Typography sx={{ fontSize: '0.62rem'}}>{trimmedString}</Typography>
  }
}

export const MultiSelector = ({ id, label, width, options, isRequired, isError, selectedValues, disabled, maxSelections = 7, handleChange }: SelectorProps) => {
  // Flag for maximum selection 
  const isMaxLimit: boolean = (selectedValues?.length === maxSelections) ? true : false;
  // alpahbetically sort drop down options 
  options.sort();

  return (
    <Autocomplete
      multiple
      limitTags={1}
      disableCloseOnSelect
      id="multi-selector"
      size="small"
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => option}
      defaultValue={selectedValues}
      value={selectedValues}
      getOptionDisabled={(option) => (isMaxLimit ? true : false)}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{ fontSize: '0.62rem', padding: 1 }}>
          <Checkbox
            size="small"
            sx={{ marginRight: 0, '& .MuiSvgIcon-root': { fontSize: 28 } }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderTags={(value, getTagProps) => (
        renderInputTags(value)
      )}
      renderInput={(params) => (
        <TextField
          // required={true}
          error={isError}
          {...params}
          label={label}
          placeholder="Search"
          InputLabelProps={{
            required: isRequired,
            style: { fontSize: '0.62rem', color: isRequired ? '#13c2c2' : isError ? 'red' : 'grey' }
          }}
          helperText={isError ? 'Mandatory Input' : isMaxLimit ? 'Maximum selection reached' : ''}
          inputProps={{ ...params.inputProps, style: { fontSize: '0.62rem' } }}
        />
      )}
      sx={{
        width: (width) ? width: 220,
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': { paddingY: '0.188rem' },
        '& .MuiChip-label': { fontSize: '0.62rem' },
        '& .MuiInputLabel-shrink': { background: 'white' },
        '& .MuiOutlinedInput-notchedOutline': { borderColor: isRequired ? '#13c2c2' : 'grey' },
        // '& .css-1q6p1pm-MuiFormHelperText-root': { color: isMaxLimit ? "blue" : "" }
      }}
      onChange={(event: any, newValue: any) => {
        handleChange(id, newValue);
      }}
    />
  );
};
