import { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Grid,
  Chip,
} from '@mui/material';
import { useTable, useSortBy, HeaderGroup } from 'react-table';
import { HeaderSort } from 'components/third-party/ReactTable';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

function ReactTable(
  { columns,
    data
  }: { columns: any, data: any }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
      <Table stickyHeader {...getTableProps()} sx={{ fontSize: '0.62rem', border: 1, borderColor: '#d6d6d6', width: '100%' }}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((hg: HeaderGroup<{}>, index: number) => {
                return (
                  <TableCell
                    {...hg.getHeaderProps([{ className: hg.className }])}
                    sx={{ fontSize: '0.60rem', position: 'sticky !important', border: 1, borderColor: '#d6d6d6' }}
                    style={{ padding: '5px' }}
                  >
                    <Stack direction="row">
                      <HeaderSort column={hg} sort tooltip={hg.tooltip} />
                    </Stack>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{
                          fontSize: '0.6rem',
                          border: 1,
                          borderColor: '#d6d6d6',
                          paddingBottom: 0,
                          paddingTop: 0,
                          padding: '5px',
                          textAlign: cell.column.id === 'perAsString' || cell.column.id === 'y' ? 'left !important' : 'right !important',
                        }}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </Grid>
  );
}
const SimulationInputsTable = ({ data, color, cnp }: { data: any, color: string, cnp: number, }) => {
  let transformedData = data?.sort((a: any, b: any) => a.yw - b.yw)?.map((input: any, index: number, arr: any[]) => {
    let percentageChange: any = input.per;
    let changeIcon = parseFloat(percentageChange) >= 0 ? (
      <ArrowUpOutlined style={{ color: 'green', fontSize: '0.62rem' }} />
    ) : (
      <ArrowDownOutlined style={{ color: 'red', fontSize: '0.62rem' }} />
    );
    return {
      perAsString: new Date(input?.sd).toLocaleString("en-US", { month: "short" }),
      weekNumber: `${input?.yw?.slice(4, 6)}`,
      date: <Stack direction="row">
        <Chip label={format(new Date(input?.dt), 'MM/dd/yyyy')} sx={{ height: '16px', width: '80px', fontSize: 'inherit' }} />
      </Stack>,
      mbp: <Stack direction="row" justifyContent={"space-between"}>
        <span>{input?.mbp}</span>
        <span>{' '} ({percentageChange}%){' '} {changeIcon}</span>
      </Stack>
    };
  });
  const columns = useMemo(
    () => [
      {
        Header: 'Month',
        accessor: 'perAsString',
        disableSortBy: true,
      },
      {
        Header: 'Week',
        accessor: 'weekNumber',
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableSortBy: true,
      },
      {
        Header: 'Modified Net Price',
        accessor: 'mbp',
        disableSortBy: true,
        tooltip: 'Net Price Change'
      },
    ],
    []
  );

  return (
    <>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <ReactTable columns={columns} data={transformedData} />
      </DndProvider>
    </>
  );
}

export default SimulationInputsTable;