export const DATE_FILTER_OPTIONS = {
  yearToDate: 'Year to Date',
  month: 'Select Month',
  quarter: 'Select Quarter',
  year: 'Select Year'
};

export const QUARTER_OPTIONS = {
  q1: 'Quarter 1',
  q2: 'Quarter 2',
  q3: 'Quarter 3',
  q4: 'Quarter 4'
};

export const MONTH_OPTIONS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getYearList = (numbrOfPrevYear, numOfNextyear) => {
  const currentYear = new Date().getFullYear();
  let previousYears = currentYear - numbrOfPrevYear;
  let nextYears = currentYear + numOfNextyear;
  let yearArray = [];
  let previousYear = previousYears;
  while (previousYear <= nextYears) {
    yearArray.push(previousYear);
    previousYear = previousYear + 1;
  }
  return yearArray;
};

export const getFormattedDate = (date, format) => {
  if (format === 'yyyy-mm-dd') {
    return `${
      date.getFullYear() +
      `-` +
      `${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}` +
      `-` +
      `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
    }`;
  } else if (format === 'dd-mm-yyyy') {
    return `${
      `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}` +
      `-` +
      `${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}` +
      `-` +
      date.getFullYear()
    }`;
  } else if (format === 'ddMMyyyy') {
    return `${
      `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}` + ` ` + `${months[date.getMonth()]}` + ` ` + date.getFullYear()
    }`;
  }
};

export const getPreviousMonthLastDay = () => {
  const currentDate = new Date();
  const lastMonth = currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1;
  const currentYear = lastMonth === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
  const lastDateOfLastMonth = new Date(currentYear, lastMonth + 1, 0);
  return lastDateOfLastMonth;
};

const leapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const currentDate = `${new Date().getFullYear()}-${
  new Date().getMonth() < 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1
}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`;

export const getSelectedDateRange = (selectedDateRange, selectedYear, selectedQuarter, selectedMonth) => {
  const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  // eslint-disable-next-line default-case
  switch (selectedDateRange) {
    case 'yearToDate':
      return {
        startDate: `01-01-${new Date().getFullYear()}`,
        endDate: new Date().getMonth() === 0 ? currentDate : getPreviousMonthLastDay(),
        key: 'selection'
      };
    case 'year':
      if (new Date().getFullYear() === selectedYear) {
        switch (currentMonth) {
          case 0:
            return { startDate: `01-01-${selectedYear}`, endDate: currentDate, key: 'selection' };
          case 1:
            return { startDate: `01-01-${selectedYear}`, endDate: `01-31-${selectedYear}`, key: 'selection' };
          case 2:
            return {
              startDate: `01-01-${selectedYear}`,
              endDate: leapYear(selectedYear) ? `02-29-${selectedYear}` : `02-28-${selectedYear}`,
              key: 'selection'
            };
          case 3:
            return { startDate: `01-01-${selectedYear}`, endDate: `03-31-${selectedYear}`, key: 'selection' };
          case 4:
            return { startDate: `01-01-${selectedYear}`, endDate: `04-30-${selectedYear}`, key: 'selection' };
          case 5:
            return { startDate: `01-01-${selectedYear}`, endDate: `05-31-${selectedYear}`, key: 'selection' };
          case 6:
            return { startDate: `01-01-${selectedYear}`, endDate: `06-30-${selectedYear}`, key: 'selection' };
          case 7:
          case 8:
            return { startDate: `01-01-${selectedYear}`, endDate: `08-31-${selectedYear}`, key: 'selection' };
          case 9:
            return { startDate: `01-01-${selectedYear}`, endDate: `09-30-${selectedYear}`, key: 'selection' };
          case 10:
            return { startDate: `01-01-${selectedYear}`, endDate: `10-31-${selectedYear}`, key: 'selection' };
          case 11:
            return { startDate: `01-01-${selectedYear}`, endDate: `11-30-${selectedYear}`, key: 'selection' };
          default:
            return { startDate: `01-01-${new Date().getFullYear()}`, endDate: currentDate, key: 'selection' };
        }
      } else {
        return {
          startDate: `01-01-${selectedYear}`,
          endDate: `12-31-${selectedYear}`,
          key: 'selection'
        };
      }
    case 'quarter':
      // eslint-disable-next-line default-case
      switch (selectedQuarter) {
        case 1:
          return {
            startDate: `01-01-${selectedYear}`,
            endDate:
              currentYear === Number(selectedYear) && currentQuarter === selectedQuarter
                ? currentMonth === 0
                  ? currentDate
                  : currentMonth === 1
                  ? `01-31-${selectedYear}`
                  : leapYear(selectedYear)
                  ? `02-29-${selectedYear}`
                  : `02-28-${selectedYear}`
                : `03-31-${selectedYear}`,
            key: 'selection'
          };
        case 2:
          return {
            startDate: `04-01-${selectedYear}`,
            endDate:
              currentYear === Number(selectedYear) && currentQuarter === selectedQuarter
                ? currentMonth === 4
                  ? `04-30-${selectedYear}`
                  : `05-31-${selectedYear}`
                : `06-30-${selectedYear}`,
            key: 'selection'
          };
        case 3:
          return {
            startDate: `07-01-${selectedYear}`,
            endDate:
              currentYear === Number(selectedYear) && currentQuarter === selectedQuarter
                ? currentMonth === 7
                  ? `07-31-${selectedYear}`
                  : `08-31-${selectedYear}`
                : `09-30-${selectedYear}`,
            key: 'selection'
          };
        case 4:
          return {
            startDate: `10-01-${selectedYear}`,
            endDate:
              currentYear === Number(selectedYear) && currentQuarter === selectedQuarter
                ? currentMonth === 11
                  ? `10-31-${selectedYear}`
                  : `11-30-${selectedYear}`
                : `12-31-${selectedYear}`,
            key: 'selection'
          };
        default:
          return { startDate: `01-01-${new Date().getFullYear()}`, endDate: currentDate, key: 'selection' };
      }
    // eslint-disable-next-line no-fallthrough
    case 'month':
      // eslint-disable-next-line default-case
      switch (selectedMonth) {
        case 0:
          return { startDate: `01-01-${selectedYear}`, endDate: `01-31-${selectedYear}`, key: 'selection' };
        case 1:
          return {
            startDate: `02-01-${selectedYear}`,
            endDate: leapYear(selectedYear) ? `02-29-${selectedYear}` : `02-28-${selectedYear}`,
            key: 'selection'
          };
        case 2:
          return { startDate: `03-01-${selectedYear}`, endDate: `03-31-${selectedYear}`, key: 'selection' };
        case 3:
          return { startDate: `04-01-${selectedYear}`, endDate: `04-30-${selectedYear}`, key: 'selection' };
        case 4:
          return { startDate: `05-01-${selectedYear}`, endDate: `05-31-${selectedYear}`, key: 'selection' };
        case 5:
          return { startDate: `06-01-${selectedYear}`, endDate: `06-30-${selectedYear}`, key: 'selection' };
        case 6:
          return { startDate: `07-01-${selectedYear}`, endDate: `07-31-${selectedYear}`, key: 'selection' };
        case 7:
          return { startDate: `08-01-${selectedYear}`, endDate: `08-31-${selectedYear}`, key: 'selection' };
        case 8:
          return { startDate: `09-01-${selectedYear}`, endDate: `09-30-${selectedYear}`, key: 'selection' };
        case 9:
          return { startDate: `10-01-${selectedYear}`, endDate: `10-31-${selectedYear}`, key: 'selection' };
        case 10:
          return { startDate: `11-01-${selectedYear}`, endDate: `11-30-${selectedYear}`, key: 'selection' };
        case 11:
          return { startDate: `12-01-${selectedYear}`, endDate: `12-31-${selectedYear}`, key: 'selection' };
        default:
          return { startDate: `01-01-${new Date().getFullYear()}`, endDate: currentDate, key: 'selection' };
      }
    default:
      return {
        startDate: `01-01-${new Date().getFullYear()}`,
        endDate: currentDate,
        key: 'selection'
      };
  }
};
