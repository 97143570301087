export const shortHandNotaion = (n) => {
  if (n >= 0) {
    if (n < 1e3) return n.toFixed(0);
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
  } else {
    if (Math.abs(n) < 1e3) return -n.toFixed(0);
    if (Math.abs(n) >= 1e3 && Math.abs(n) < 1e6) return -(Math.abs(n) / 1e3).toFixed(1) + 'K';
    if (Math.abs(n) >= 1e6 && Math.abs(n) < 1e9) return -(Math.abs(n) / 1e6).toFixed(1) + 'M';
    if (Math.abs(n) >= 1e9 && Math.abs(n) < 1e12) return -(Math.abs(n) / 1e9).toFixed(1) + 'B';
    if (Math.abs(n) >= 1e12) return -(Math.abs(n) / 1e12).toFixed(1) + 'T';
  }
};
export const shortHandNotaionMill = (n) => {
  if (n >= 0) {
    return Number(n.toFixed(1));
  } else {
    return Number(-Math.abs(n).toFixed(1));
  }
};
