import { isNull, camelCase } from 'lodash';

// get product level key
export const getProductLevelKey = (level: string) => {
  return camelCase(level);
};

// get Category dropdown options
export const getCategoryFilterOptions = (filters: any) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (!isNull(obj.cat) && obj.cat != 'BABY WIPES') categories.push(obj.cat);
  });
  categories.sort();
  categories = [...new Set(categories)];
  return categories;
};
export const getSubCategoryFilterOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.subcat) && obj.cat === category) categories.push(obj.subcat);
    } else {
      if (!isNull(obj.subcat)) categories.push(obj.subcat);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getBrandOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.brd) && obj.cat === category) categories.push(obj.brd);
    } else {
      if (!isNull(obj.brd)) categories.push(obj.brd);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getSegmentOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.seg) && obj.cat === category) categories.push(obj.seg);
    } else {
      if (!isNull(obj.seg)) categories.push(obj.seg);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getManufacturerOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.manuf) && obj.cat === category) categories.push(obj.manuf);
    } else {
      if (!isNull(obj.manuf)) categories.push(obj.manuf);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getCountOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.cnt) && obj.cat === category) categories.push(obj.cnt);
    } else {
      if (!isNull(obj.cnt)) categories.push(obj.cnt);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getDayNightOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.dnt) && obj.cat === category) categories.push(obj.dnt);
    } else {
      if (!isNull(obj.dnt)) categories.push(obj.dnt);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getWingNoWingOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.wng) && obj.cat === category) categories.push(obj.wng);
    } else {
      if (!isNull(obj.wng)) categories.push(obj.wng);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getCoveringOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.cov) && obj.cat === category) categories.push(obj.cov);
    } else {
      if (!isNull(obj.cov)) categories.push(obj.cov);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getPackagingOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.pkg) && obj.cat === category) categories.push(obj.pkg);
    } else {
      if (!isNull(obj.pkg)) categories.push(obj.pkg);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getSubSegmentOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.sseg) && obj.sseg !== '' && obj.cat === category) categories.push(obj.sseg);
    } else {
      if (!isNull(obj.sseg) && obj.sseg !== '') categories.push(obj.sseg);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getSizeOptions = (filters: any, category: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (category && category.length > 0) {
      if (!isNull(obj.size) && obj.cat === category) categories.push(obj.size);
    } else {
      if (!isNull(obj.size)) categories.push(obj.size);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getSubBrandOptions = (filters: any, category: string, brand: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (brand && brand.length > 0) {
      if (!isNull(obj.sbrd) && obj.brd === brand) categories.push(obj.sbrd);
    } else if (category && category.length > 0) {
      if (!isNull(obj.sbrd) && obj.cat === category) categories.push(obj.sbrd);
    } else {
      if (!isNull(obj.sbrd)) categories.push(obj.sbrd);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getTierFilterOptions = (filters: any, category: string, subCategory: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (subCategory && subCategory.length > 0) {
      if (!isNull(obj.tier) && obj.subcat === subCategory) categories.push(obj.tier);
    } else if (category && category.length > 0) {
      if (!isNull(obj.tier) && obj.cat === category) categories.push(obj.tier);
    } else {
      if (!isNull(obj.tier)) categories.push(obj.tier);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};
export const getRegionOptions = (filters: any, category: string, subCategory: string, tier: string) => {
  let categories: string[] = [];
  filters.map((obj: any) => {
    if (tier && tier.length > 0) {
      if (!isNull(obj.reg) && obj.tier === tier) categories.push(obj.reg);
    } else if (subCategory && subCategory.length > 0) {
      if (!isNull(obj.reg) && obj.subcat === subCategory) categories.push(obj.reg);
    } else if (category && category.length > 0) {
      if (!isNull(obj.reg) && obj.cat === category) categories.push(obj.reg);
    } else {
      if (!isNull(obj.reg)) categories.push(obj.reg);
    }
  });
  categories = [...new Set(categories)];
  return categories;
};

// get Manufacturer dropdown options
export const getManufacturerFilterOptions = (filters: any, categories: string[]) => {
  let manufactures: string[] = [];
  let newFilters = filters.filter((obj: any) => {
    return categories.includes(obj.cat);
  });
  if (categories.length === 0) newFilters = filters;
  newFilters.map((obj: any) => {
    if (!isNull(obj.mfr)) manufactures.push(obj.mfr);
  });
  manufactures = [...new Set(manufactures)];
  return { newFilters, manufactures };
};

// get Brand dropdown options
export const getBrandFilterOptions = (filters: any, manufactures: string[], categories?: any) => {
  let brands: string[] = [];
  let newFilters = [];
  if (manufactures.length > 0) {
    newFilters = filters.filter((obj: any) => {
      return manufactures.includes(obj.mfr);
    });
  } else {
    newFilters = filters.filter((obj: any) => {
      return categories.includes(obj.cat);
    });
  }
  newFilters.map((obj: any) => {
    if (!isNull(obj.brd)) brands.push(obj.brd);
  });
  brands = [...new Set(brands)];
  return { newFilters, brands };
};

// get Sub Brand dropdown options
export const getSubBrandFilterOptions = (filters: any, brands: string[]) => {
  let subBrands: string[] = [];
  let newFilters = filters.filter((obj: any) => {
    return brands.includes(obj.brd);
  });
  newFilters.map((obj: any) => {
    if (!isNull(obj.subBrd)) subBrands.push(obj.subBrd);
  });
  subBrands = [...new Set(subBrands)];
  return { newFilters, subBrands };
};
export const getSalesChannelFilterOptions = (filters: any, subBrands: string[]) => {
  let salesChannel: string[] = [];
  let newFilters = filters.filter((obj: any) => {
    return subBrands.includes(obj.subBrd);
  });
  newFilters.map((obj: any) => {
    if (!isNull(obj.scha)) salesChannel.push(obj.scha);
  });
  salesChannel = [...new Set(salesChannel)];
  return { newFilters, salesChannel };
};
export const getSalesOfficeFilterOptions = (filters: any, salesChannel: string[]) => {
  let salesOffice: string[] = [];
  let newFilters = filters.filter((obj: any) => {
    return salesChannel.includes(obj.scha);
  });
  newFilters.map((obj: any) => {
    if (!isNull(obj.soff)) salesOffice.push(obj.soff);
  });
  salesOffice = [...new Set(salesOffice)];
  return { newFilters, salesOffice };
};

// get Region dropdown options
export const getRegionFilterOptions = (filters: any) => {
  let regions: string[] = [];
  filters.map((obj: any) => {
    if (!isNull(obj.regn)) regions.push(obj.regn);
  });
  regions = [...new Set(regions)];
  return regions;
};

// get new Product Level filter options
export const getAllProductLevelFilterOptions = (data: any) => {
  let { filterType, selectedFilters, filters, source, currentProductLevelFilters } = data;
  let newProductLevelFilters: any = { ...currentProductLevelFilters };
  let cat;
  let mfr;
  let brd;
  let subBrd;
  let regions;
  let salesChannel;
  let salesOffice;

  if (source === 'Nis') {
    cat = getCategoryFilterOptions(filters.data);
    mfr = getManufacturerFilterOptions(filters.data, selectedFilters.category.length > 0 ? selectedFilters.category : cat);
    brd = getBrandFilterOptions(mfr.newFilters, selectedFilters.manufacturer.length > 0 ? selectedFilters.manufacturer : mfr.manufactures);
    subBrd = getSubBrandFilterOptions(brd.newFilters, selectedFilters.brand.length > 0 ? selectedFilters.brand : brd.brands);
    regions = getRegionFilterOptions(subBrd.newFilters);
  } else if (source === 'Sell In') {
    cat = getCategoryFilterOptions(filters.data);
    mfr = {};
    brd = getBrandFilterOptions(filters.data, [], selectedFilters.category.length > 0 ? selectedFilters.category : cat);
    subBrd = getSubBrandFilterOptions(brd.newFilters, selectedFilters.brand.length > 0 ? selectedFilters.brand : brd.brands);
    salesChannel = getSalesChannelFilterOptions(
      subBrd.newFilters,
      selectedFilters.subBrand.length > 0 ? selectedFilters.subBrand : subBrd.subBrands
    );
    salesOffice = getSalesOfficeFilterOptions(
      salesChannel.newFilters,
      selectedFilters.salesChannel.length > 0 ? selectedFilters.salesChannel : salesChannel.salesChannel
    );
  } else {
    cat = getCategoryFilterOptions(filters.data);
    mfr = {};
    brd = getBrandFilterOptions(filters.data, [], selectedFilters.category.length > 0 ? selectedFilters.category : cat);
    subBrd = getSubBrandFilterOptions(brd.newFilters, selectedFilters.brand.length > 0 ? selectedFilters.brand : brd.brands);
  }

  switch (filterType) {
    case 'category':
      if (source === 'Nis') {
        newProductLevelFilters.manufacturer = mfr.manufactures;
        newProductLevelFilters.region = regions;
      } else if (source === 'Sell In') {
        newProductLevelFilters.salesChannel = salesChannel?.salesChannel;
        newProductLevelFilters.salesOffice = salesOffice?.salesOffice;
      }
      newProductLevelFilters.brand = brd.brands;
      newProductLevelFilters.subBrand = subBrd.subBrands;
      break;
    case 'manufacturer':
      newProductLevelFilters.brand = brd.brands;
      newProductLevelFilters.subBrand = subBrd.subBrands;
      newProductLevelFilters.region = regions;
      break;
    case 'brand':
      newProductLevelFilters.subBrand = subBrd.subBrands;
      if (source === 'Nis') {
        newProductLevelFilters.region = regions;
      } else if (source === 'Sell In') {
        newProductLevelFilters.salesChannel = salesChannel?.salesChannel;
        newProductLevelFilters.salesOffice = salesOffice?.salesOffice;
      }
      break;
    case 'subBrand':
      if (source === 'Nis') {
        newProductLevelFilters.region = regions;
      } else if (source === 'Sell In') {
        newProductLevelFilters.salesChannel = salesChannel?.salesChannel;
        newProductLevelFilters.salesOffice = salesOffice?.salesOffice;
      }
      break;
    case 'salesChannel':
      newProductLevelFilters.salesOffice = salesOffice?.salesOffice;
      break;
    default:
      if (source === 'Nis') {
        newProductLevelFilters.manufacturer = mfr.manufactures;
        newProductLevelFilters.region = regions;
        newProductLevelFilters.channel = filters.channels;
      }
      if (source === 'Sell In') {
        newProductLevelFilters.salesChannel = salesChannel?.salesChannel;
        newProductLevelFilters.salesOffice = salesOffice?.salesOffice;
      }
      newProductLevelFilters.category = cat;
      newProductLevelFilters.brand = brd.brands;
      newProductLevelFilters.subBrand = subBrd.subBrands;
  }

  return newProductLevelFilters;
};

// remove/unselect any selections that does not belongs to the higher level selections in the filter hierarchy
export const unselectUserSelectedFilters = (data: any) => {
  let { filterType, allFilters, selectedFilters, source } = data;

  let newSelectedFilters: any = { ...selectedFilters };

  switch (filterType) {
    case 'category':
      if (source === 'Nis') {
        newSelectedFilters.manufacturer = removefilterNotInList(selectedFilters.manufacturer, allFilters.manufacturer);
        newSelectedFilters.region = removefilterNotInList(selectedFilters.region, allFilters.region);
      }
      newSelectedFilters.brand = removefilterNotInList(selectedFilters.brand, allFilters.brand);
      newSelectedFilters.subBrand = removefilterNotInList(selectedFilters.subBrand, allFilters.subBrand);
      break;
    case 'manufacturer':
      newSelectedFilters.brand = removefilterNotInList(selectedFilters.brand, allFilters.brand);
      newSelectedFilters.subBrand = removefilterNotInList(selectedFilters.subBrand, allFilters.subBrand);
      newSelectedFilters.region = removefilterNotInList(selectedFilters.region, allFilters.region);
      break;
    case 'brand':
      newSelectedFilters.subBrand = removefilterNotInList(selectedFilters.subBrand, allFilters.subBrand);
      if (source === 'Nis') {
        newSelectedFilters.region = removefilterNotInList(selectedFilters.region, allFilters.region);
      } else if (source === 'Sell In') {
        newSelectedFilters.salesChannel = removefilterNotInList(selectedFilters.salesChannel, allFilters.salesChannel);
        newSelectedFilters.salesOffice = removefilterNotInList(selectedFilters.salesOffice, allFilters.salesOffice);
      }
      break;
    case 'subBrand':
      if (source === 'Nis') {
        newSelectedFilters.region = removefilterNotInList(selectedFilters.region, allFilters.region);
      } else if (source === 'Sell In') {
        newSelectedFilters.salesChannel = removefilterNotInList(selectedFilters.salesChannel, allFilters.salesChannel);
        newSelectedFilters.salesOffice = removefilterNotInList(selectedFilters.salesOffice, allFilters.salesOffice);
      }
      break;
    case 'salesChannel':
      newSelectedFilters.salesOffice = removefilterNotInList(selectedFilters.salesOffice, allFilters.salesOffice);
      break;
    default:
  }
  return newSelectedFilters;
};

const removefilterNotInList = (filterArr: any, newlist: any) => {
  let newFilterArr: any[] = [];
  filterArr.forEach((filter: string) => {
    if (newlist.includes(filter)) {
      newFilterArr.push(filter);
    }
  });
  return newFilterArr;
};

// format api data
export const formatProductLevelFilters = (filters: any) => {
  let categories: string[] = [];
  let manufactures: string[] = [];
  let brands: string[] = [];
  let subBrands: string[] = [];
  let regions: string[] = [];
  let salesChannel: string[] = [];
  let salesOffice: string[] = [];

  filters.data.map((obj: any) => {
    if (!isNull(obj.cat)) categories.push(obj.cat);
    if (!isNull(obj.mfr)) manufactures.push(obj.mfr);
    if (!isNull(obj.brd)) brands.push(obj.brd);
    if (!isNull(obj.subBrd)) subBrands.push(obj.subBrd);
    if (!isNull(obj.regn)) regions.push(obj.regn);
    if (!isNull(obj.scha)) salesChannel.push(obj.scha);
    if (!isNull(obj.soff)) salesOffice.push(obj.soff);
  });

  const productLevelFilters: any = {
    category: [...new Set(categories)],
    manufacturer: [...new Set(manufactures)],
    brand: [...new Set(brands)],
    subBrand: [...new Set(subBrands)],
    region: [...new Set(regions)],
    channel: [...new Set(filters.channels)],
    salesChannel: [...new Set(salesChannel)],
    salesOffice: [...new Set(salesOffice)]
  };

  return productLevelFilters;
};

export const downLoadSVG =
  '<svg viewBox="70 64 896 896" focusable="false" data-icon="download" width="1.4em" height="1.5em" fill="currentColor" aria-hidden="true"><path d="M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>';

export const getFontSize = () => {
  let fontSize = 7;
  if (window.innerWidth <= 567) {
    fontSize = 7;
  } else if (window.innerWidth > 567 && window.innerWidth <= 1280) {
    fontSize = 8;
  } else if (window.innerWidth > 1280 && window.innerWidth <= 1422) {
    fontSize = 9;
  } else if (window.innerWidth > 1422 && window.innerWidth <= 1600) {
    fontSize = 11;
  } else if (window.innerWidth > 1600 && window.innerWidth <= 1920) {
    fontSize = 12;
  } else if (window.innerWidth > 1920) {
    fontSize = 14;
  }

  return fontSize;
};

export const getFontSizeForCharts = () => {
  let fontSize = 7;
  if (window.innerWidth <= 567) {
    fontSize = 7;
  } else if (window.innerWidth > 567 && window.innerWidth <= 1600) {
    fontSize = 8;
  } else if (window.innerWidth > 1600 && window.innerWidth <= 1707) {
    fontSize = 9;
  } else if (window.innerWidth > 1707 && window.innerWidth <= 1920) {
    fontSize = 11;
  } else if (window.innerWidth > 1920) {
    fontSize = 13;
  }

  return fontSize;
};

export const exportPNG = async (chartId: string) => {
  let x = await ApexCharts.exec(chartId, 'dataURI', { scale: 10 });

  let url = window.URL.createObjectURL(await (await fetch(x.imgURI)).blob());
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${chartId}.jpeg`);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
  URL.revokeObjectURL(url);
}
