import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import { APP_DEFAULT_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx}>
    {isIcon ? (
      <svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1612 1390" width="50" height="50">
        <title>Kimberly-Clark_logo-svg</title>

        <g id="g105">
          <path
            id="path27"
            fillRule="evenodd"
            className="shp0"
            d="M805.53 0C1250.09 0 1611.06 311.12 1611.06 694.62C1611.06 1078.12 1250.09 1389.24 805.53 1389.24C360.97 1389.24 0 1078.12 0 694.62C0 309.85 359.7 -1.27 805.53 0ZM656.08 152.39L656.08 586.68C621.88 507.95 545.89 438.11 468.63 391.12L143.12 391.12C248.25 469.85 415.43 593.03 415.43 692.08C415.43 741.61 355.9 750.49 321.71 750.49L116.52 750.49L116.52 986.69L438.23 986.69C510.42 986.69 614.28 960.02 656.08 849.54L656.08 1165.74L954.98 1165.74L954.98 849.54C996.78 961.29 1100.64 986.69 1172.83 986.69L1494.54 986.69L1494.54 750.49L1289.36 750.49C1255.16 750.49 1195.63 740.34 1195.63 692.08C1195.63 593.03 1362.82 469.85 1467.94 391.12L1142.44 391.12C1065.18 438.11 989.18 507.95 954.98 586.68L954.98 152.39L656.08 152.39Z"
          />
        </g>
      </svg>
    ) : (
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="100%" viewBox="0 0 634 96" enableBackground="new 0 0 634 96">
        <path
          fill="#FBFCFD"
          opacity="1.000000"
          stroke="none"
          d="
M470.000000,97.000000 
	C313.386810,97.000000 157.273621,97.000000 1.080214,97.000000 
	C1.080214,65.064598 1.080214,33.128952 1.080214,1.096651 
	C212.333328,1.096651 423.666656,1.096651 634.971680,1.096651 
	C634.971680,33.059628 634.971680,65.029831 634.971680,97.000000 
	C580.128967,97.000000 525.314453,97.000000 470.000000,97.000000 
M16.760277,74.902397 
	C16.932764,75.177635 17.177576,75.350342 17.583668,75.444107 
	C17.583668,75.444107 17.591805,75.535774 17.878735,76.091988 
	C19.231110,77.092827 20.583485,78.093666 21.984232,79.003906 
	C21.984232,79.003906 21.907427,79.072105 22.193665,79.591492 
	C22.718132,79.842361 23.242599,80.093231 23.860674,80.183701 
	C23.860674,80.183701 23.710394,80.292816 23.972910,80.755180 
	C24.304966,80.858871 24.637022,80.962570 24.987057,80.998184 
	C24.987057,80.998184 24.936148,81.046837 25.314648,81.602730 
	C26.732229,82.238449 28.149811,82.874161 29.648092,83.480408 
	C29.648092,83.480408 29.692183,83.554138 30.178843,83.995209 
	C31.438784,84.357590 32.698723,84.719971 34.583778,85.511223 
	C46.025322,88.768333 57.226257,88.609718 68.670967,83.127701 
	C69.166466,82.795982 69.661964,82.464272 70.074524,82.085991 
	C70.074524,82.085991 70.078972,82.181007 70.680611,82.124809 
	C71.147514,81.768990 71.614418,81.413170 72.021774,80.998520 
	C72.021774,80.998520 72.046844,81.078377 72.676422,81.002670 
	C73.490311,80.364616 74.304199,79.726562 75.028015,79.020927 
	C75.028015,79.020927 75.078781,79.121437 75.663803,78.987831 
	C76.219086,78.435959 76.774368,77.884087 77.120323,77.036835 
	C77.120323,77.036835 77.296234,77.353256 77.780548,77.231773 
	C78.105850,77.087509 78.322182,76.845757 78.465538,76.425751 
	C78.465538,76.425751 78.551445,76.446693 79.074944,76.188416 
	C79.706268,75.455338 80.337593,74.722260 81.001892,74.001839 
	C81.001892,74.001839 80.989258,73.968857 81.532509,73.755104 
	C81.817642,73.260925 82.102776,72.766747 82.155693,72.161377 
	C82.155693,72.161377 82.352753,72.327072 82.928566,72.011551 
	C84.304916,69.680550 85.681259,67.349541 87.485878,64.626480 
	C87.636848,64.076973 87.787811,63.527462 88.391190,62.359909 
	C88.714981,57.016354 89.038780,51.672794 89.599518,45.898567 
	C89.610924,45.547855 89.486931,45.254421 89.297127,44.258381 
	C88.550987,42.480156 87.804855,40.701931 87.005402,38.983879 
	C87.005402,38.983879 87.083511,38.964825 87.023979,38.356552 
	C86.703865,37.878212 86.383751,37.399872 86.007607,36.983860 
	C86.007607,36.983860 86.088737,36.962799 86.022530,36.328999 
	C85.715958,35.801994 85.409386,35.274990 85.030655,34.307278 
	C85.030655,34.307278 84.691399,34.016876 84.496399,33.420567 
	C83.387299,32.145248 82.278198,30.869928 81.087326,29.238197 
	C81.087326,29.238197 80.786888,29.029743 80.577721,28.428164 
	C79.067413,27.259947 77.557098,26.091730 76.008484,25.000048 
	C76.008484,25.000048 76.076157,24.947540 75.794510,24.382204 
	C74.895897,23.872902 73.997292,23.363600 73.016502,22.978268 
	C73.016502,22.978268 73.134529,22.887766 72.768585,22.303101 
	C69.191368,20.836969 65.614159,19.370838 61.546631,17.464775 
	C60.684174,17.353722 59.821720,17.242668 58.272240,16.675406 
	C45.445755,14.583448 33.252075,15.682064 21.975685,23.906956 
	C21.642344,24.245583 21.309002,24.584209 20.434170,25.015762 
	C20.217861,25.295307 20.001554,25.574854 19.903782,25.901731 
	C19.903782,25.901731 19.839579,25.791420 19.190699,25.968710 
	C16.030672,30.294579 12.870645,34.620449 9.593606,39.088028 
	C9.593606,39.088028 9.593852,39.271809 9.104647,39.706551 
	C8.766438,40.474731 8.428229,41.242912 7.888158,42.827850 
	C7.591107,46.886971 7.294056,50.946095 6.853596,55.813465 
	C9.435327,61.324856 12.017057,66.836243 14.884439,72.081467 
	C14.884439,72.081467 14.627553,72.375488 14.763790,72.845222 
	C14.940530,73.145958 15.200024,73.330002 15.627361,73.387566 
	C15.627361,73.387566 15.608052,73.471016 15.774611,73.908447 
	C15.959939,74.184639 16.216805,74.349052 16.652925,74.365944 
	C16.652925,74.365944 16.606489,74.469498 16.760277,74.902397 
M518.384277,73.595688 
	C518.384277,73.595688 518.559875,73.726738 518.896240,74.203003 
	C519.594421,74.499321 520.292603,74.795639 521.153137,75.273422 
	C521.153137,75.273422 521.386780,75.204941 521.980469,75.745697 
	C525.405823,75.614380 528.831238,75.483063 532.027893,75.317680 
	C532.027893,75.317680 532.242981,75.402534 532.838501,75.369438 
	C533.333374,75.069443 533.828186,74.769447 534.296509,74.266441 
	C534.296509,74.266441 534.390442,74.448364 535.051270,74.268745 
	C535.933777,73.551949 536.816284,72.835152 538.042847,72.577866 
	C538.203491,72.681816 538.364136,72.785774 538.464111,73.701141 
	C539.088074,74.489525 539.676270,75.923195 540.341797,75.960075 
	C544.244690,76.176331 548.165649,76.066299 552.711060,76.066299 
	C552.711060,69.596634 552.711060,63.791500 552.903137,57.129833 
	C552.932190,55.083878 552.961243,53.037926 553.235474,50.398209 
	C553.529236,44.751682 550.408081,41.274715 545.562317,39.445198 
	C538.278687,36.695274 530.900330,37.245365 523.807007,40.286144 
	C519.780945,42.012043 518.208374,45.429134 518.153442,49.917076 
	C520.071472,49.917076 521.532166,49.917076 523.660278,50.049278 
	C524.109497,50.004833 524.558716,49.960384 525.826355,50.084957 
	C528.765198,50.409996 531.294922,50.189762 532.357727,46.656300 
	C532.622131,45.777187 534.096863,45.262104 535.709229,44.733646 
	C536.642029,44.891830 537.574890,45.050014 538.768005,45.852554 
	C539.250122,46.901974 539.732178,47.951397 540.036072,49.665028 
	C540.045044,50.120499 540.054016,50.575966 539.532104,51.723022 
	C536.013855,52.243763 532.495544,52.764500 528.354858,53.185951 
	C527.568420,53.440109 526.782043,53.694267 525.242432,53.900066 
	C522.792969,55.241158 520.343567,56.582249 517.987000,57.981491 
	C517.987000,57.981491 517.924316,57.891579 517.366577,58.101330 
	C516.950623,58.749008 516.534668,59.396687 516.028503,60.062618 
	C516.028503,60.062618 516.062561,60.148106 515.525635,60.658684 
	C513.423584,63.160553 512.917480,61.910767 513.081055,59.560287 
	C513.604797,52.033638 514.192322,44.511433 514.954590,36.334412 
	C514.929321,35.882542 514.904053,35.430676 515.171936,34.220074 
	C515.382385,30.667654 515.592896,27.115234 515.809570,23.457968 
	C510.954193,23.457968 506.716614,23.457968 501.944916,23.457968 
	C501.734009,28.147324 501.534668,32.580021 501.105042,37.749535 
	C501.127563,38.519508 501.150085,39.289486 500.786987,40.761398 
	C500.000031,52.441166 499.213043,64.120941 498.428680,75.762215 
	C503.343658,75.762215 507.580414,75.762215 511.923615,75.762215 
	C511.923615,73.289330 511.923615,71.149780 512.036194,68.561417 
	C512.036194,68.561417 512.050903,68.098946 512.595459,67.761414 
	C514.160095,69.326111 515.724731,70.890808 517.419800,72.641319 
	C517.419800,72.641319 517.606689,72.770134 517.738464,73.061684 
	C517.869568,73.241890 518.042908,73.360085 518.384277,73.595688 
M414.462280,70.453293 
	C414.462280,70.453293 414.545929,70.362617 415.004883,69.947556 
	C415.367584,68.982185 415.730255,68.016808 416.635986,66.628082 
	C419.434509,60.447254 422.233032,54.266422 425.581451,47.704079 
	C426.066803,46.476124 426.552155,45.248165 426.995117,44.006691 
	C426.995117,44.006691 427.025177,44.040173 427.577942,43.701153 
	C430.033112,39.556702 430.033112,39.556702 426.194824,39.103222 
	C425.135864,39.151600 424.076904,39.199982 422.174255,39.084618 
	C419.735382,39.509071 416.196075,37.491833 415.600586,42.150349 
	C415.600586,42.150349 415.656403,42.246349 415.090179,42.716492 
	C414.610107,44.079308 414.130066,45.442123 413.778351,46.859493 
	C413.778351,46.859493 413.710602,46.737587 413.219055,47.114315 
	C412.868683,48.069992 412.518341,49.025669 411.744476,50.567825 
	C410.381836,53.694931 409.019196,56.822037 407.309540,60.745487 
	C406.027100,56.868744 405.049133,53.912331 404.081604,50.153732 
	C402.716431,46.525711 401.351288,42.897690 399.199677,39.105709 
	C398.134521,39.160519 397.069397,39.215328 395.341461,39.110371 
	C394.893311,39.163582 394.445190,39.216793 393.176636,39.104023 
	C391.787109,39.158566 390.397614,39.213108 388.114410,39.099709 
	C387.078888,39.044304 386.043335,38.988903 384.906189,38.928066 
	C385.213165,33.472317 385.494843,28.465693 385.789886,23.221821 
	C382.130005,23.221821 379.302979,23.468189 376.537048,23.162262 
	C372.690308,22.736794 371.559235,24.390549 371.776489,27.955740 
	C371.905640,30.075527 371.911194,32.397572 371.172668,34.321793 
	C370.403931,36.324883 368.673157,37.958771 366.667694,39.388988 
	C363.777954,40.432892 360.888184,41.476791 357.695343,42.630188 
	C357.430908,41.768494 357.029388,40.460033 356.699158,39.383850 
	C352.004761,39.383850 347.745239,39.383850 343.426422,39.383850 
	C342.669922,51.701401 341.933319,63.695232 341.194641,75.722397 
	C346.334229,75.722397 350.897827,75.722397 356.021729,75.722397 
	C356.021729,71.936790 356.021729,68.476372 356.344421,64.306282 
	C357.244019,60.541542 358.143616,56.776806 359.497284,52.910439 
	C359.820160,52.794327 360.014526,52.567913 360.837494,52.117836 
	C363.225739,52.009533 365.613983,51.901234 368.545563,51.848331 
	C368.718048,51.790112 368.890533,51.731888 369.713348,52.238663 
	C369.543152,57.491871 369.372986,62.745075 368.950500,68.648666 
	C368.991089,69.106575 369.031677,69.564476 368.856232,70.920097 
	C368.856232,72.520332 368.856232,74.120560 368.856232,75.803024 
	C373.360687,75.803024 377.594299,75.803024 382.029816,75.803024 
	C382.265839,72.043793 382.486664,68.526237 382.954926,64.228394 
	C382.958435,63.147842 382.961914,62.067291 383.305023,60.250957 
	C383.796387,53.685684 384.287750,47.120415 384.874023,39.286972 
	C386.792358,43.769539 388.197327,47.052540 389.572449,50.192783 
	C389.572449,50.192783 389.474060,50.300453 389.525055,50.961857 
	C389.900269,51.738316 390.275452,52.514774 390.657837,53.152912 
	C390.657837,53.152912 390.544250,53.232201 390.600616,54.004410 
	C392.006744,57.560352 393.412872,61.116299 394.713776,64.807739 
	C394.713776,64.807739 394.790192,64.961327 394.783722,65.612846 
	C395.083160,66.168854 395.382629,66.724861 395.701874,67.150124 
	C395.701874,67.150124 395.587433,67.216438 395.639862,68.005653 
	C396.246368,71.878990 396.852844,75.752335 397.504639,79.722252 
	C397.504639,79.722252 397.479309,79.636810 396.971100,80.072121 
	C396.160919,81.946442 395.350739,83.820763 394.648315,85.878296 
	C394.648315,85.878296 394.608643,85.669472 394.070190,86.109253 
	C392.943298,88.541222 391.816406,90.973198 390.542480,93.722420 
	C407.007050,93.757584 401.767059,96.488586 408.712494,82.986366 
	C408.855988,82.707474 408.830902,82.341881 409.168335,81.787079 
	C409.168335,81.787079 409.138855,81.423134 409.674988,80.936546 
	C410.963776,78.098526 412.252594,75.260506 413.359924,72.445969 
	C413.359924,72.445969 413.523254,72.363503 413.974487,72.005478 
	C414.175720,71.473938 414.376923,70.942390 414.462280,70.453293 
M480.988953,34.003990 
	C480.988953,34.003990 480.920380,34.054104 481.205994,34.670307 
	C482.144409,36.373867 483.082825,38.077423 484.915741,40.055134 
	C489.318420,40.055134 493.721100,40.055134 498.487885,40.055134 
	C498.237335,37.939491 498.064758,36.482082 497.980347,34.326824 
	C497.279114,32.890907 496.577881,31.454990 495.827301,29.542980 
	C495.682281,29.226110 495.438263,29.036793 494.908752,28.431322 
	C494.179108,27.832672 493.449432,27.234024 492.346466,26.092731 
	C491.582581,25.697908 490.818726,25.303083 490.018707,24.994858 
	C490.018707,24.994858 490.089447,24.933176 489.635895,24.363337 
	C470.274078,16.883759 457.178925,23.912540 448.781525,46.374844 
	C446.224487,46.627831 443.618774,46.885635 440.117554,47.020657 
	C437.410187,47.058945 434.702789,47.097229 431.266327,47.047428 
	C428.834534,50.467045 426.402771,53.886662 423.136871,58.479221 
	C427.667969,58.479221 430.808075,58.135086 433.844360,58.550156 
	C440.366608,59.441757 445.168213,57.934910 446.990631,50.877045 
	C447.118195,50.383045 448.556305,50.227459 449.129395,50.612701 
	C449.138519,51.102222 449.147644,51.591747 448.868378,52.671684 
	C449.269928,58.364552 449.511597,64.105881 453.765717,68.909462 
	C453.765717,68.909462 454.015015,69.006927 454.163300,69.586121 
	C454.755157,70.087074 455.346985,70.588028 455.994232,71.008507 
	C455.994232,71.008507 455.914490,71.064964 456.219849,71.682671 
	C464.880005,77.911926 474.251526,77.943741 484.559479,74.892319 
	C485.049072,74.603432 485.538666,74.314552 486.662415,73.994804 
	C486.978912,73.730492 487.295441,73.466179 487.852356,73.263077 
	C487.852356,73.263077 488.009827,73.071388 488.707886,73.006142 
	C494.252625,69.854897 497.277924,65.183716 497.820435,58.461948 
	C496.071167,58.333508 494.793182,58.221516 493.512939,58.148766 
	C487.437347,57.803513 481.258820,57.247551 478.959442,64.961815 
	C478.959442,64.961815 478.941193,64.869362 478.154449,65.006088 
	C470.329437,67.569351 465.137115,64.936676 464.874725,56.911667 
	C464.664459,50.480385 466.565094,43.980091 467.386719,37.537388 
	C467.386719,37.537388 467.506683,37.456390 468.015442,37.188061 
	C468.199585,36.857849 468.383728,36.527637 469.065338,35.967987 
	C469.724121,35.346821 470.382935,34.725655 471.730804,34.007164 
	C472.805298,33.579769 473.879761,33.152374 475.723541,32.805016 
	C476.495605,32.793930 477.267639,32.782845 478.607819,33.072708 
	C479.388794,33.408424 480.169769,33.744137 480.988953,34.003990 
M340.688049,59.655716 
	C340.719299,58.781082 340.750549,57.906448 340.990723,56.372116 
	C340.923065,55.922066 340.855377,55.472012 340.961945,54.206974 
	C340.906128,47.868641 338.540100,42.838062 332.615326,39.998051 
	C321.481567,34.661118 308.499329,39.097538 302.831146,50.076649 
	C302.232758,51.235737 301.500458,52.325703 300.830322,53.447765 
	C300.172180,52.965267 299.514069,52.482773 298.970398,51.485977 
	C298.923248,51.316795 298.876129,51.147617 298.982208,50.238491 
	C298.342010,48.462074 297.701782,46.685658 297.040375,44.949905 
	C297.040375,44.949905 297.084167,44.931782 296.948364,44.258244 
	C292.371918,38.598705 286.048401,36.629368 280.040588,39.130489 
	C277.960358,39.996502 276.009216,41.172523 273.470917,41.943924 
	C273.264069,41.619045 273.057190,41.294167 273.001038,40.143150 
	C273.686188,34.665707 274.371307,29.188263 275.092560,23.422289 
	C269.820496,23.422289 265.536346,23.422289 260.624084,23.422289 
	C259.762177,34.680779 258.906342,45.859413 257.460724,56.507931 
	C257.216980,55.187210 256.973206,53.866489 256.595337,51.917793 
	C256.653381,51.067657 256.711426,50.217525 256.964539,48.723969 
	C256.711884,48.145432 256.459259,47.566895 255.966843,46.310162 
	C251.139648,36.230747 240.658447,35.050926 230.163330,43.405582 
	C222.328445,36.708111 217.032318,36.455296 207.306595,41.679695 
	C205.417542,40.531258 204.079544,39.023346 203.028152,39.202023 
	C199.035629,39.880524 194.464371,36.964600 190.507660,40.685265 
	C189.936798,40.153080 189.365936,39.620895 187.893524,39.062599 
	C183.957535,39.062599 180.021530,39.062599 175.738419,39.062599 
	C174.872513,51.620682 174.045074,63.620983 173.203796,75.821922 
	C178.059525,75.821922 182.347321,75.821922 187.466965,75.905113 
	C188.023926,75.908859 188.580902,75.912605 190.049957,76.018135 
	C194.625168,76.018135 199.200378,76.018135 203.807770,76.018135 
	C204.319626,68.429718 204.673508,61.625957 205.270065,54.843544 
	C205.727509,49.642559 208.849564,46.327293 212.519638,47.211971 
	C217.084030,48.312225 216.735214,52.130695 216.503952,55.670235 
	C216.073486,62.258423 215.587326,68.842972 215.109665,75.637985 
	C220.157547,75.637985 224.826355,75.637985 229.615387,75.637985 
	C229.729340,74.074615 229.819000,72.929787 229.895279,71.784073 
	C230.303696,65.648193 230.518738,59.491291 231.187057,53.383945 
	C231.581253,49.781811 233.622467,46.881001 237.683228,47.234505 
	C241.567627,47.572655 241.993301,50.804581 241.913620,53.985233 
	C241.880341,55.312511 241.712143,56.636135 241.614914,57.962128 
	C241.183304,63.848888 240.755188,69.735901 240.294205,76.055748 
	C244.299408,76.055748 247.787796,75.801376 251.221024,76.127541 
	C254.630066,76.451408 255.166916,74.674759 255.590637,72.201012 
	C256.064941,72.470497 256.539276,72.739990 257.089996,73.669685 
	C257.099274,74.164940 256.898956,74.894806 257.150177,75.119278 
	C259.748657,77.441071 270.181274,76.009888 272.414429,73.086266 
	C282.951538,79.600899 292.474792,77.136612 298.053162,65.907425 
	C298.610291,62.984940 299.167419,60.062458 299.724579,57.139973 
	C302.002991,62.931454 300.974915,70.656830 307.578278,73.324608 
	C313.185852,75.590096 319.740936,76.530350 325.824677,76.345276 
	C333.872345,76.100464 338.644958,71.194206 339.649109,63.930626 
	C334.424561,65.056931 328.689026,61.476231 324.270233,67.507942 
	C323.396606,68.700485 318.987000,68.620651 317.211639,67.515450 
	C314.750641,65.983414 313.541168,62.885731 316.946930,60.051136 
	C324.638458,59.971069 332.329956,59.891006 340.688049,59.655716 
M178.903519,33.070793 
	C181.936035,33.036968 184.968552,33.003147 188.819809,33.067219 
	C190.379990,29.854746 191.888367,26.641872 188.003891,23.268909 
	C184.707443,23.317814 181.410980,23.366716 177.390152,23.478306 
	C177.154388,25.691208 176.918625,27.904112 176.462189,30.679070 
	C176.506927,31.074539 176.551666,31.470009 176.421829,32.445408 
	C176.947906,32.619328 177.473984,32.793247 178.903519,33.070793 
M168.119278,70.924210 
	C168.119278,70.924210 168.278046,70.703110 168.190323,70.007393 
	C165.836426,66.298592 163.482513,62.589787 161.010132,58.191929 
	C159.301453,55.354755 157.845566,52.319195 155.816467,49.733463 
	C153.565643,46.865181 154.251160,45.000862 156.534241,42.554970 
	C161.053604,37.713348 165.228729,32.549133 169.511765,27.489155 
	C170.474121,26.352234 171.295166,25.095695 172.672653,23.227961 
	C166.930008,23.227961 162.302429,23.105890 157.688766,23.305380 
	C156.374374,23.362213 154.666840,24.002262 153.879532,24.974232 
	C149.406479,30.496468 145.157745,36.200397 140.224014,42.175907 
	C139.381180,43.060875 138.538361,43.945843 136.998932,45.562241 
	C137.457565,37.541996 137.856140,30.571947 138.253204,23.628126 
	C132.626419,23.628126 127.755455,23.628126 122.571701,23.628126 
	C121.356415,41.285004 120.168236,58.547970 118.987801,75.698471 
	C124.768715,75.698471 129.674103,75.698471 134.800949,75.698471 
	C135.252548,68.583099 135.563049,61.800697 136.183380,55.046749 
	C136.374100,52.970299 137.411880,50.971653 138.198151,49.628933 
	C141.401917,55.145935 144.605682,60.662933 147.940048,66.880119 
	C148.896744,68.628250 149.731689,70.459167 150.847015,72.099434 
	C151.813354,73.520607 153.023911,75.761330 154.253983,75.848877 
	C159.910217,76.251427 165.611145,76.026184 172.224716,76.026184 
	C170.446579,73.618202 169.386841,72.183075 168.119278,70.924210 
M556.236572,44.785168 
	C556.251770,46.196674 556.267029,47.608181 556.027405,49.745300 
	C556.052612,50.510788 556.077820,51.276276 555.758606,52.751057 
	C555.640320,55.836578 555.522034,58.922100 555.176025,62.821838 
	C555.139343,64.225334 555.102661,65.628838 554.716370,67.737350 
	C554.571533,70.455139 554.426758,73.172928 554.290833,75.724174 
	C559.528320,75.724174 564.091064,75.724174 568.736572,75.724174 
	C569.230713,68.936897 569.698669,62.509270 570.609558,55.696533 
	C571.091370,54.853920 571.573242,54.011307 572.763245,52.895214 
	C576.316833,51.961628 579.870361,51.028038 583.836365,49.986095 
	C583.251099,58.796741 582.695251,67.164543 582.121277,75.806137 
	C587.028931,75.806137 591.453003,75.806137 596.241516,75.806137 
	C596.727600,69.956497 597.206482,64.194336 597.685303,58.432167 
	C601.422485,63.317593 604.007080,68.320671 606.863892,73.163162 
	C607.588501,74.391266 609.131165,75.847549 610.381042,75.929367 
	C615.265747,76.249146 620.183838,76.059959 625.877869,76.059959 
	C621.144104,68.457123 616.889099,61.623245 612.644592,54.806320 
	C617.367188,49.548489 621.725342,44.696438 626.777283,39.071930 
	C622.190491,39.071930 618.873413,39.314209 615.607849,39.007809 
	C611.905334,38.660408 609.389282,39.796921 607.246399,42.945095 
	C604.719604,46.657455 601.605164,49.969933 597.836609,54.563789 
	C598.619080,43.225666 599.290649,33.495155 599.981934,23.478672 
	C595.053284,23.478672 590.632507,23.478672 585.770447,23.478672 
	C585.022095,32.040886 584.285767,40.466209 583.549377,48.891537 
	C583.116882,48.863659 582.684387,48.835785 582.251953,48.807911 
	C582.476807,45.774216 582.701721,42.740520 582.972595,39.086094 
	C581.189209,39.392437 579.968018,39.602203 578.475525,39.633312 
	C578.226562,39.638069 578.058716,39.757286 577.297119,39.922909 
	C575.795227,40.777668 574.293274,41.632427 572.164429,42.773499 
	C571.433533,42.178394 570.702576,41.583294 570.054504,40.122215 
	C565.543091,40.106625 561.031677,40.091034 556.343079,40.843456 
	C556.399536,41.889996 556.455933,42.936531 556.236572,44.785168 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M191.020264,41.008167 
	C194.464371,36.964600 199.035629,39.880524 203.028152,39.202023 
	C204.079544,39.023346 205.417542,40.531258 207.306595,41.679695 
	C217.032318,36.455296 222.328445,36.708111 230.163330,43.405582 
	C240.658447,35.050926 251.139648,36.230747 256.041748,46.962994 
	C256.334259,48.199680 256.551880,48.783539 256.769470,49.367393 
	C256.711426,50.217525 256.653381,51.067657 256.428741,52.603336 
	C255.866409,59.532391 255.470673,65.775902 255.074951,72.019417 
	C255.166916,74.674759 254.630066,76.451408 251.221024,76.127541 
	C247.787796,75.801376 244.299408,76.055748 240.294205,76.055748 
	C240.755188,69.735901 241.183304,63.848888 241.614914,57.962128 
	C241.712143,56.636135 241.880341,55.312511 241.913620,53.985233 
	C241.993301,50.804581 241.567627,47.572655 237.683228,47.234505 
	C233.622467,46.881001 231.581253,49.781811 231.187057,53.383945 
	C230.518738,59.491291 230.303696,65.648193 229.895279,71.784073 
	C229.819000,72.929787 229.729340,74.074615 229.615387,75.637985 
	C224.826355,75.637985 220.157547,75.637985 215.109665,75.637985 
	C215.587326,68.842972 216.073486,62.258423 216.503952,55.670235 
	C216.735214,52.130695 217.084030,48.312225 212.519638,47.211971 
	C208.849564,46.327293 205.727509,49.642559 205.270065,54.843544 
	C204.673508,61.625957 204.319626,68.429718 203.807770,76.018135 
	C199.200378,76.018135 194.625168,76.018135 189.566833,75.532288 
	C189.729233,63.700344 190.374741,52.354256 191.020264,41.008167 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M578.746826,39.811970 
	C579.968018,39.602203 581.189209,39.392437 582.972595,39.086094 
	C582.701721,42.740520 582.476807,45.774216 582.251953,48.807911 
	C582.684387,48.835785 583.116882,48.863659 583.549377,48.891537 
	C584.285767,40.466209 585.022095,32.040886 585.770447,23.478672 
	C590.632507,23.478672 595.053284,23.478672 599.981934,23.478672 
	C599.290649,33.495155 598.619080,43.225666 597.836609,54.563789 
	C601.605164,49.969933 604.719604,46.657455 607.246399,42.945095 
	C609.389282,39.796921 611.905334,38.660408 615.607849,39.007809 
	C618.873413,39.314209 622.190491,39.071930 626.777283,39.071930 
	C621.725342,44.696438 617.367188,49.548489 612.644592,54.806320 
	C616.889099,61.623245 621.144104,68.457123 625.877869,76.059959 
	C620.183838,76.059959 615.265747,76.249146 610.381042,75.929367 
	C609.131165,75.847549 607.588501,74.391266 606.863892,73.163162 
	C604.007080,68.320671 601.422485,63.317593 597.685303,58.432167 
	C597.206482,64.194336 596.727600,69.956497 596.241516,75.806137 
	C591.453003,75.806137 587.028931,75.806137 582.121277,75.806137 
	C582.695251,67.164543 583.251099,58.796741 583.836365,49.986095 
	C579.870361,51.028038 576.316833,51.961628 572.398438,52.638062 
	C572.169189,49.479374 573.502197,48.719398 576.322815,49.516827 
	C577.514709,49.853806 580.088196,49.315563 580.179382,48.828236 
	C580.541748,46.891224 580.373718,44.764824 579.872986,42.846745 
	C579.791016,42.533119 577.044983,42.984364 575.533447,42.948814 
	C574.616333,42.927250 573.705200,42.649715 572.791382,42.487183 
	C574.293274,41.632427 575.795227,40.777668 577.767029,39.941666 
	C578.411194,39.925842 578.581177,39.876358 578.746826,39.811970 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M316.025146,59.892105 
	C313.541168,62.885731 314.750641,65.983414 317.211639,67.515450 
	C318.987000,68.620651 323.396606,68.700485 324.270233,67.507942 
	C328.689026,61.476231 334.424561,65.056931 339.649109,63.930626 
	C338.644958,71.194206 333.872345,76.100464 325.824677,76.345276 
	C319.740936,76.530350 313.185852,75.590096 307.578278,73.324608 
	C300.974915,70.656830 302.002991,62.931454 299.724579,57.139973 
	C299.167419,60.062458 298.610291,62.984940 297.678040,66.088470 
	C297.302887,66.269516 296.982391,65.980858 296.988495,65.576126 
	C296.380402,63.598267 296.017670,61.857349 295.102264,60.485157 
	C292.841187,57.095741 294.735840,52.608200 298.855957,52.000267 
	C299.514069,52.482773 300.172180,52.965267 300.830322,53.447754 
	C301.500458,52.325703 302.232758,51.235737 302.831146,50.076649 
	C308.499329,39.097538 321.481567,34.661118 332.615326,39.998051 
	C338.540100,42.838062 340.906128,47.868641 340.538055,54.690971 
	C339.426514,56.355331 338.738892,57.535690 338.571533,57.822971 
	C330.533569,58.560635 323.279358,59.226368 316.025146,59.892105 
M326.538300,47.930546 
	C324.362762,47.286369 321.751221,45.588428 320.121216,46.262798 
	C318.049622,47.119846 316.773682,49.899998 314.403442,52.756279 
	C319.968750,52.756279 323.797211,52.756279 328.065918,52.756279 
	C327.603546,51.105312 327.259552,49.877029 326.538300,47.930546 
z"
        />
        <path
          fill="#2C3991"
          opacity="1.000000"
          stroke="none"
          d="
M488.009827,73.071396 
	C488.009827,73.071388 487.852356,73.263077 487.412231,73.245491 
	C486.657471,73.493820 486.342865,73.759735 486.028259,74.025658 
	C485.538666,74.314552 485.049072,74.603432 484.021729,74.639999 
	C482.336578,74.277435 481.189178,74.167191 479.739746,74.058792 
	C478.961639,74.375763 478.485596,74.690895 477.618713,75.012573 
	C475.275818,75.038582 473.244934,75.416924 471.386719,75.008972 
	C466.191101,73.868340 461.066895,72.402473 455.914490,71.064964 
	C455.914490,71.064964 455.994232,71.008507 455.948425,70.722282 
	C455.273407,69.959679 454.644226,69.483307 454.015015,69.006927 
	C454.015015,69.006927 453.765717,68.909462 453.792603,68.367081 
	C452.850769,64.226738 451.882080,60.628769 450.942474,56.583549 
	C450.953857,53.741440 450.936127,51.346588 450.933258,48.708450 
	C450.948120,48.465164 450.997192,47.980164 451.294769,47.947456 
	C451.911438,47.512058 452.230530,47.109367 452.549622,46.706676 
	C452.035278,46.477989 451.520935,46.249306 451.037415,45.640015 
	C453.779053,31.011063 465.224823,22.991985 479.809662,23.981535 
	C483.241974,24.214411 486.663147,24.611586 490.089447,24.933176 
	C490.089447,24.933176 490.018707,24.994858 490.095764,25.329874 
	C491.021790,25.988384 491.870758,26.311878 492.719757,26.635372 
	C493.449432,27.234024 494.179108,27.832672 494.822510,28.778080 
	C494.665558,29.231968 494.510162,29.388227 494.538849,29.437679 
	C494.661469,29.648993 494.838379,29.828815 495.033691,30.367313 
	C495.715851,32.145962 496.359741,33.577408 496.997253,35.427624 
	C496.243500,36.882397 495.500977,38.800098 494.747955,38.804226 
	C491.180756,38.823780 487.610687,38.320915 484.020813,37.637589 
	C482.973419,36.198704 481.946899,35.126411 480.920380,34.054111 
	C480.920380,34.054104 480.988953,34.003990 480.950958,33.721752 
	C479.955200,33.216930 478.997467,32.994343 478.039703,32.771759 
	C477.267639,32.782845 476.495605,32.793930 475.078094,32.550911 
	C473.302338,32.899372 472.172058,33.501938 471.041748,34.104500 
	C470.382935,34.725655 469.724121,35.346821 468.495636,36.144714 
	C467.786194,36.699760 467.646454,37.078079 467.506714,37.456394 
	C467.506683,37.456390 467.386719,37.537388 467.072449,37.484791 
	C462.007263,43.335709 461.470978,58.591530 465.816986,64.205894 
	C469.252258,68.643639 475.073883,68.937943 478.941193,64.869362 
	C478.941193,64.869362 478.959442,64.961815 479.311401,64.961067 
	C480.811768,64.016418 481.939362,63.045586 483.115082,62.137005 
	C484.081787,61.389965 485.059570,60.202461 486.112854,60.108185 
	C489.175507,59.834064 492.278564,60.010975 497.042480,60.010975 
	C494.514709,63.917316 492.760834,66.719505 490.906097,69.453262 
	C490.041443,70.727669 488.981354,71.869484 488.009827,73.071396 
z"
        />
        <path
          fill="#2D3A90"
          opacity="1.000000"
          stroke="none"
          d="
M423.017944,39.248363 
	C424.076904,39.199982 425.135864,39.151600 426.593353,39.577431 
	C427.002991,41.381149 427.014069,42.710663 427.025177,44.040173 
	C427.025177,44.040173 426.995117,44.006691 426.651123,44.074425 
	C425.881958,45.456638 425.456757,46.771118 425.031586,48.085594 
	C422.233032,54.266422 419.434509,60.447254 415.987274,66.886215 
	C415.074341,68.217094 414.810120,69.289856 414.545929,70.362617 
	C414.545929,70.362617 414.462280,70.453293 414.152649,70.444984 
	C413.736420,71.078949 413.629822,71.721230 413.523254,72.363503 
	C413.523254,72.363503 413.359924,72.445969 413.068451,72.406693 
	C411.564270,75.385979 410.351562,78.404556 409.138855,81.423134 
	C409.138855,81.423134 409.168335,81.787079 408.690186,81.958046 
	C407.138580,83.757439 405.923187,85.311050 405.017853,87.028114 
	C402.143188,92.480103 400.384918,93.178871 394.608643,91.090416 
	C394.608643,89.214363 394.608643,87.441917 394.608643,85.669472 
	C394.608643,85.669472 394.648315,85.878296 394.976379,85.766533 
	C396.029419,83.648781 396.754364,81.642799 397.479309,79.636810 
	C397.479309,79.636810 397.504639,79.722252 397.862549,79.644318 
	C400.622437,75.634598 399.751190,71.548088 395.587433,67.216438 
	C395.587433,67.216438 395.701874,67.150124 395.765991,66.871536 
	C395.483490,66.049072 395.136841,65.505203 394.790192,64.961334 
	C394.790192,64.961327 394.713776,64.807739 394.814270,64.295586 
	C393.457947,60.266357 392.001099,56.749279 390.544250,53.232201 
	C390.544250,53.232201 390.657837,53.152912 390.759399,52.840668 
	C390.398651,51.785763 389.936371,51.043106 389.474060,50.300449 
	C389.474060,50.300453 389.572449,50.192783 389.677856,49.838051 
	C389.524872,46.078094 389.266479,42.672871 389.008087,39.267651 
	C390.397614,39.213108 391.787109,39.158566 393.636841,39.454414 
	C394.732788,39.626583 395.368500,39.448368 396.004242,39.270142 
	C397.069397,39.215328 398.134521,39.160519 399.597961,39.576927 
	C401.354553,43.684074 402.712830,47.320000 404.071136,50.955925 
	C405.049133,53.912331 406.027100,56.868744 407.309540,60.745487 
	C409.019196,56.822037 410.381836,53.694931 412.301453,50.205376 
	C413.142487,48.807812 413.426544,47.772697 413.710602,46.737587 
	C413.710602,46.737587 413.778351,46.859493 414.109406,46.741001 
	C414.845764,45.163792 415.251099,43.705070 415.656403,42.246349 
	C415.656403,42.246349 415.600586,42.150349 415.941284,41.987915 
	C418.527313,40.966438 420.772644,40.107403 423.017944,39.248363 
z"
        />
        <path
          fill="#303C90"
          opacity="1.000000"
          stroke="none"
          d="
M68.054024,83.153534 
	C57.226257,88.609718 46.025322,88.768333 34.111717,84.968658 
	C32.323830,84.135445 31.008005,83.844795 29.692183,83.554138 
	C29.692183,83.554138 29.648092,83.480408 29.586138,83.132698 
	C27.994839,82.205605 26.465492,81.626221 24.936148,81.046837 
	C24.936148,81.046837 24.987057,80.998184 24.847233,80.794762 
	C24.375071,80.491837 24.042732,80.392326 23.710394,80.292816 
	C23.710394,80.292816 23.860674,80.183701 23.734699,79.953621 
	C23.041626,79.506393 22.474527,79.289246 21.907427,79.072105 
	C21.907427,79.072105 21.984232,79.003906 21.937454,78.687653 
	C20.457718,77.426193 19.024761,76.480988 17.591805,75.535774 
	C17.591805,75.535774 17.583668,75.444107 17.574663,75.147278 
	C17.245935,74.723465 16.926212,74.596481 16.606487,74.469498 
	C16.606489,74.469498 16.652925,74.365944 16.614565,74.098999 
	C16.253489,73.711700 15.930771,73.591354 15.608053,73.471016 
	C15.608052,73.471016 15.627361,73.387566 15.603941,73.106071 
	C15.262864,72.674881 14.945209,72.525185 14.627553,72.375488 
	C14.627553,72.375488 14.884439,72.081467 14.850330,71.783890 
	C14.705894,68.708694 16.128572,68.024132 18.765797,67.874809 
	C23.535921,67.604729 28.276184,66.807236 33.280792,66.135162 
	C33.712029,66.034645 33.891396,66.028915 34.412579,66.031105 
	C35.442825,65.591705 36.131260,65.144379 36.859074,64.787170 
	C36.898457,64.877281 36.764771,64.733009 37.107681,64.825294 
	C38.554947,65.287354 39.659306,65.657135 40.840485,66.477570 
	C40.924347,69.694786 40.931385,72.461349 40.947521,75.443054 
	C40.956619,75.658195 41.062504,76.075638 41.125671,76.225586 
	C41.188835,76.375526 41.605648,76.519440 41.605648,76.519440 
	C41.605648,76.519440 41.984024,76.241051 42.333855,76.221756 
	C43.150272,76.219757 43.616859,76.237061 44.391647,76.484016 
	C47.798046,76.477898 50.896248,76.242126 54.224819,75.935303 
	C54.455193,75.864258 54.928020,75.958641 55.151840,75.910164 
	C55.665581,75.732010 55.881332,75.525024 55.988174,75.162788 
	C55.953438,75.084839 56.101349,74.999672 56.371635,74.702393 
	C56.771057,72.427330 56.606323,70.363487 57.149883,68.507042 
	C57.433334,67.538948 59.009644,66.225266 59.924610,66.283493 
	C65.944405,66.666634 71.933090,67.661758 77.952667,67.877136 
	C81.310349,67.997269 83.028900,68.704567 82.352753,72.327072 
	C82.352753,72.327072 82.155693,72.161377 81.959183,72.258614 
	C81.504868,72.893517 81.247063,73.431183 80.989258,73.968857 
	C80.989258,73.968857 81.001892,74.001839 80.608414,74.007202 
	C79.660446,74.823944 79.105942,75.635315 78.551445,76.446693 
	C78.551445,76.446693 78.465538,76.425751 78.154411,76.418518 
	C77.660934,76.725273 77.478584,77.039268 77.296234,77.353256 
	C77.296234,77.353256 77.120323,77.036835 76.873466,77.124947 
	C76.110664,77.849182 75.594727,78.485313 75.078781,79.121437 
	C75.078781,79.121437 75.028015,79.020927 74.706696,79.031464 
	C73.605865,79.720787 72.826355,80.399582 72.046844,81.078377 
	C72.046844,81.078377 72.021774,80.998520 71.734673,81.028656 
	C70.991371,81.432854 70.535172,81.806931 70.078972,82.181007 
	C70.078972,82.181007 70.074524,82.085991 69.792480,82.087410 
	C69.024971,82.443733 68.539497,82.798637 68.054024,83.153534 
z"
        />
        <path
          fill="#2E3A8F"
          opacity="1.000000"
          stroke="none"
          d="
M540.062988,51.031429 
	C540.054016,50.575966 540.045044,50.120499 540.438599,49.268585 
	C542.141846,46.327080 541.116943,45.263107 538.507690,45.208199 
	C537.574890,45.050014 536.642029,44.891830 535.292725,44.395187 
	C533.780884,44.410164 532.550476,44.554680 531.617065,45.158482 
	C529.340759,46.631027 527.202026,48.316311 525.007935,49.915943 
	C524.558716,49.960384 524.109497,50.004833 523.117493,49.729786 
	C519.220581,47.675110 519.945068,45.921635 522.331177,43.790352 
	C532.098633,35.066212 548.024353,38.770321 552.990356,50.991970 
	C552.961243,53.037926 552.932190,55.083878 552.439331,57.683159 
	C551.654968,61.606354 551.147949,64.971024 551.053772,68.347214 
	C550.874451,74.773331 547.102905,77.143784 541.064209,74.399635 
	C540.173035,73.994659 539.369080,73.397812 538.524780,72.889725 
	C538.364136,72.785774 538.203491,72.681816 537.594299,72.299339 
	C536.227295,72.829994 535.308899,73.639183 534.390442,74.448364 
	C534.390442,74.448364 534.296509,74.266441 533.974731,74.253174 
	C533.182922,74.627449 532.712952,75.014992 532.242981,75.402534 
	C532.242981,75.402534 532.027893,75.317680 531.770203,75.197647 
	C528.137268,75.120056 524.762024,75.162498 521.386780,75.204941 
	C521.386780,75.204941 521.153137,75.273422 521.011841,74.822212 
	C520.100342,74.156242 519.330139,73.941490 518.559875,73.726738 
	C518.559875,73.726738 518.384277,73.595688 518.433716,73.255142 
	C518.190979,72.866440 517.898865,72.818291 517.606689,72.770142 
	C517.606689,72.770134 517.419800,72.641319 517.527100,72.157661 
	C517.110413,67.832039 516.586487,63.990074 516.062561,60.148109 
	C516.062561,60.148106 516.028503,60.062618 516.388489,59.991692 
	C517.140442,59.244370 517.532349,58.567974 517.924316,57.891579 
	C517.924316,57.891579 517.987000,57.981491 518.318970,57.972023 
	C521.099182,56.624508 523.547363,55.286465 525.995605,53.948425 
	C526.782043,53.694267 527.568420,53.440109 528.759583,53.559563 
	C532.309998,53.836109 535.469666,53.866688 538.588867,53.526680 
	C539.164062,53.463982 539.575806,51.901608 540.062988,51.031429 
M539.193970,60.469952 
	C537.070190,57.327366 531.612183,57.667496 529.485413,61.474892 
	C528.645752,62.978100 528.531433,65.990662 529.491211,67.090385 
	C530.489624,68.234451 533.986084,68.842003 534.965759,68.008957 
	C536.870178,66.389664 537.778442,63.598801 539.193970,60.469952 
z"
        />
        <path
          fill="#2E3B90"
          opacity="1.000000"
          stroke="none"
          d="
M369.063019,51.673660 
	C368.890533,51.731888 368.718048,51.790112 368.260315,51.457001 
	C364.794312,47.734318 362.399384,49.725346 360.080444,52.231197 
	C360.014526,52.567913 359.820160,52.794327 358.907776,52.996109 
	C353.196655,56.221588 355.261414,60.744282 356.021729,65.015953 
	C356.021729,68.476372 356.021729,71.936790 356.021729,75.722397 
	C350.897827,75.722397 346.334229,75.722397 341.194641,75.722397 
	C341.933319,63.695232 342.669922,51.701401 343.426422,39.383850 
	C347.745239,39.383850 352.004761,39.383850 356.699158,39.383850 
	C357.029388,40.460033 357.430908,41.768494 357.695343,42.630188 
	C360.888184,41.476791 363.777954,40.432892 367.400543,39.719818 
	C369.421844,40.365982 370.710327,40.681316 371.999176,41.330536 
	C371.998077,42.109608 371.996613,42.554790 371.646362,42.964153 
	C370.293762,42.804508 369.289917,42.680691 369.063019,42.652706 
	C369.063019,45.940975 369.063019,48.807316 369.063019,51.673660 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M297.460205,66.417038 
	C292.474792,77.136612 282.951538,79.600899 272.414429,73.086266 
	C270.181274,76.009888 259.748657,77.441071 257.150177,75.119278 
	C256.898956,74.894806 257.099274,74.164940 257.221130,72.970154 
	C257.585022,67.193092 257.817810,62.115574 258.050537,57.038052 
	C258.906342,45.859413 259.762177,34.680779 260.624084,23.422289 
	C265.536346,23.422289 269.820496,23.422289 275.092560,23.422289 
	C274.371307,29.188263 273.686188,34.665707 272.672974,40.266083 
	C272.344910,37.476315 272.344910,34.563606 272.344910,31.353769 
	C270.452698,31.507397 269.053680,31.620983 267.654663,31.734570 
	C267.353577,31.293011 267.052521,30.851452 266.751434,30.409893 
	C268.432190,29.061129 270.112946,27.712364 271.823608,26.339602 
	C265.731964,23.224951 262.460175,25.081158 262.008331,31.803009 
	C261.542084,38.739605 261.210754,45.687767 260.600067,52.611439 
	C260.013275,59.264236 258.517456,65.897415 262.959442,72.005074 
	C264.679077,74.369568 267.228577,74.717735 269.260986,72.445503 
	C272.601471,68.710823 271.974365,65.185493 269.413574,61.313038 
	C267.543213,58.484634 265.243805,55.065182 269.173828,52.248409 
	C272.782959,49.661625 274.061951,46.355991 273.999115,42.206940 
	C276.009216,41.172523 277.960358,39.996502 280.040588,39.130489 
	C286.048401,36.629368 292.371918,38.598705 296.650085,44.579445 
	C287.675690,39.563374 285.350250,39.145027 280.142059,42.325401 
	C281.758179,42.774025 283.060974,43.135670 284.322968,43.485992 
	C284.135223,45.188576 283.972656,46.662693 283.457764,47.966240 
	C277.253174,45.716816 274.176910,47.183304 272.853607,53.030247 
	C272.276764,55.578953 271.946198,58.304058 272.172211,60.890640 
	C272.791351,67.975746 275.792816,69.774216 282.461700,67.758568 
	C280.648010,69.722809 278.765717,71.341011 276.175568,73.567711 
	C280.032898,73.650200 284.126953,74.343719 287.939392,73.597939 
	C290.325928,73.131088 292.288696,70.497765 295.406799,68.091759 
	C292.409424,67.263206 291.066895,66.892097 289.740204,66.525368 
	C289.833649,65.294624 289.920471,64.151070 290.376160,62.999908 
	C294.560822,60.363804 294.584778,65.649368 296.982391,65.980858 
	C296.982391,65.980858 297.302887,66.269516 297.460205,66.417038 
M277.316589,45.638180 
	C277.348785,44.949371 277.381012,44.260559 277.413239,43.571751 
	C277.006592,43.687504 276.599915,43.803253 276.193268,43.919003 
	C276.381897,44.562557 276.570496,45.206112 277.316589,45.638180 
z"
        />
        <path
          fill="#2B3890"
          opacity="1.000000"
          stroke="none"
          d="
M512.050903,68.098946 
	C512.050903,68.098946 512.036194,68.561417 511.723694,69.070419 
	C510.627258,77.947479 503.791779,71.883118 500.108093,75.724586 
	C500.108093,72.561386 500.012329,70.940552 500.124146,69.334175 
	C500.716797,60.819019 501.404755,52.310207 501.940033,43.791676 
	C502.016907,42.568512 501.444489,41.304546 501.172607,40.059464 
	C501.150085,39.289486 501.127563,38.519508 501.577606,37.254601 
	C502.360413,34.709866 502.980255,32.650688 502.918518,30.612160 
	C502.776154,25.914183 505.078644,24.910879 509.384125,24.830042 
	C513.803162,24.747074 514.260254,26.808475 514.091736,30.259314 
	C514.015747,31.815960 514.596252,33.404652 514.878784,34.978806 
	C514.904053,35.430676 514.929321,35.882542 514.485107,36.779369 
	C513.702209,37.772110 513.135559,38.309662 513.112976,38.869202 
	C512.719238,48.610847 512.392395,58.355190 512.050903,68.098946 
z"
        />
        <path
          fill="#2C3992"
          opacity="1.000000"
          stroke="none"
          d="
M371.995117,42.999977 
	C371.996613,42.554790 371.998077,42.109608 372.008972,40.911400 
	C372.783997,34.952717 373.549591,29.747051 374.324951,24.475134 
	C376.284790,24.732700 377.285095,25.088161 378.210510,24.948332 
	C383.109833,24.208075 384.780426,26.404854 384.016968,31.027695 
	C383.911102,31.668705 384.000031,32.341885 383.728546,33.233269 
	C383.313324,34.642365 383.168335,35.818359 383.017761,37.440052 
	C382.768982,40.139038 382.293488,42.395973 382.328796,44.644894 
	C382.414368,50.094166 382.737823,55.539700 382.965424,60.986740 
	C382.961914,62.067291 382.958435,63.147842 382.471985,64.730209 
	C381.660370,66.097198 381.172150,66.937698 381.033813,67.832298 
	C380.663300,70.228432 382.047028,73.623901 377.957916,73.910675 
	C374.443542,74.157143 370.289948,75.270432 369.072266,70.022385 
	C369.031677,69.564476 368.991089,69.106575 369.451599,68.212997 
	C370.297211,67.081612 370.907501,66.400040 370.945374,65.688019 
	C371.347565,58.128117 371.658813,50.563381 371.995117,42.999977 
z"
        />
        <path
          fill="#2D3A91"
          opacity="1.000000"
          stroke="none"
          d="
M572.477905,42.630341 
	C573.705200,42.649715 574.616333,42.927250 575.533447,42.948814 
	C577.044983,42.984364 579.791016,42.533119 579.872986,42.846745 
	C580.373718,44.764824 580.541748,46.891224 580.179382,48.828236 
	C580.088196,49.315563 577.514709,49.853806 576.322815,49.516827 
	C573.502197,48.719398 572.169189,49.479374 572.044312,52.774803 
	C571.573242,54.011307 571.091370,54.853920 570.056030,56.087326 
	C569.003906,61.009647 568.176270,65.537270 568.122314,70.074089 
	C568.086304,73.104637 566.784058,74.454880 564.224304,74.718201 
	C561.545166,74.993805 558.815613,74.779793 556.071228,74.779793 
	C555.704773,71.955742 555.385376,69.494034 555.065979,67.032333 
	C555.102661,65.628838 555.139343,64.225334 555.580994,62.336250 
	C556.025024,58.581028 556.064026,55.311394 556.103088,52.041763 
	C556.077820,51.276276 556.052612,50.510788 556.477661,49.294247 
	C556.789429,47.223152 556.650879,45.603111 556.512390,43.983070 
	C556.455933,42.936531 556.399536,41.889996 556.805359,40.529984 
	C561.502319,40.473740 565.737000,40.730972 569.971680,40.988197 
	C570.702576,41.583294 571.433533,42.178394 572.477905,42.630341 
z"
        />
        <path
          fill="#2E3B90"
          opacity="1.000000"
          stroke="none"
          d="
M186.635101,75.821922 
	C182.347321,75.821922 178.059525,75.821922 173.203796,75.821922 
	C174.045074,63.620983 174.872513,51.620682 175.738419,39.062599 
	C180.021530,39.062599 183.957535,39.062599 188.411957,39.520962 
	C188.165298,51.926857 187.400208,63.874390 186.635101,75.821922 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M273.735016,42.075432 
	C274.061951,46.355991 272.782959,49.661625 269.173828,52.248409 
	C265.243805,55.065182 267.543213,58.484634 269.413574,61.313038 
	C271.974365,65.185493 272.601471,68.710823 269.260986,72.445503 
	C267.228577,74.717735 264.679077,74.369568 262.959442,72.005074 
	C258.517456,65.897415 260.013275,59.264236 260.600067,52.611439 
	C261.210754,45.687767 261.542084,38.739605 262.008331,31.803009 
	C262.460175,25.081158 265.731964,23.224951 271.823608,26.339602 
	C270.112946,27.712364 268.432190,29.061129 266.751434,30.409893 
	C267.052521,30.851452 267.353577,31.293011 267.654663,31.734570 
	C269.053680,31.620983 270.452698,31.507397 272.344910,31.353769 
	C272.344910,34.563606 272.344910,37.476315 272.597626,40.679153 
	C273.057190,41.294167 273.264069,41.619045 273.735016,42.075432 
z"
        />
        <path
          fill="#263492"
          opacity="1.000000"
          stroke="none"
          d="
M20.975658,24.922836 
	C21.309002,24.584209 21.642344,24.245583 22.630095,23.901108 
	C24.230799,23.673746 25.194319,23.507950 26.120564,23.221586 
	C31.731396,21.486919 37.333717,19.724726 43.348618,17.981892 
	C45.503510,17.666964 47.248669,17.341873 49.370926,17.012547 
	C50.502232,17.014877 51.256432,17.021446 52.179119,17.268307 
	C52.898899,17.676113 53.450195,17.843628 54.000938,18.412537 
	C54.000568,19.884848 54.000748,20.955763 53.537868,22.023842 
	C50.290749,22.020054 47.482376,21.803602 44.733379,22.113604 
	C43.454529,22.257814 42.290920,23.423952 41.028641,24.566998 
	C40.887932,27.663507 40.793663,30.319870 40.435562,33.024456 
	C40.352531,33.722992 40.533329,34.373302 40.808010,35.412697 
	C40.837837,36.861729 40.773777,37.921680 40.334770,38.983025 
	C39.002308,38.804642 37.529953,38.955173 37.169018,38.392620 
	C34.376358,34.039993 30.054688,35.305202 26.074816,34.950165 
	C22.790358,34.657166 19.557798,33.782421 16.067039,33.120892 
	C17.658791,30.028360 18.749184,27.909889 19.839577,25.791420 
	C19.839579,25.791420 19.903782,25.901731 20.114508,25.831903 
	C20.542042,25.482328 20.758850,25.202583 20.975658,24.922836 
z"
        />
        <path
          fill="#2D3A90"
          opacity="1.000000"
          stroke="none"
          d="
M140.832703,41.842514 
	C145.157745,36.200397 149.406479,30.496468 153.879532,24.974232 
	C154.666840,24.002262 156.374374,23.362213 157.688766,23.305380 
	C162.302429,23.105890 166.930008,23.227961 172.672653,23.227961 
	C171.295166,25.095695 170.474121,26.352234 169.511765,27.489155 
	C165.228729,32.549133 161.053604,37.713348 156.534241,42.554970 
	C154.251160,45.000862 153.565643,46.865181 155.816467,49.733463 
	C157.845566,52.319195 159.301453,55.354755 161.014954,58.586937 
	C161.019760,58.981945 160.911133,59.083126 160.571808,59.013821 
	C158.444504,57.328857 156.907303,54.899918 154.819656,54.256260 
	C149.435486,52.596222 152.748764,46.550575 149.162598,44.239296 
	C149.955109,43.517155 150.647217,42.598751 151.568146,42.127628 
	C152.504257,41.648735 153.665924,41.610756 156.021729,41.098640 
	C154.239609,39.306992 153.031113,37.168129 152.014267,37.255665 
	C149.653793,37.458847 147.338791,38.630169 145.097443,39.618023 
	C143.673462,40.245628 142.414673,41.248070 141.019684,42.022278 
	C140.957565,41.962132 140.832703,41.842518 140.832703,41.842514 
z"
        />
        <path
          fill="#2D3A90"
          opacity="1.000000"
          stroke="none"
          d="
M138.061783,48.937382 
	C137.411880,50.971653 136.374100,52.970299 136.183380,55.046749 
	C135.563049,61.800697 135.252548,68.583099 134.800949,75.698471 
	C129.674103,75.698471 124.768715,75.698471 118.987801,75.698471 
	C120.168236,58.547970 121.356415,41.285004 122.571701,23.628126 
	C127.755455,23.628126 132.626419,23.628126 138.253204,23.628126 
	C137.856140,30.571947 137.457565,37.541996 136.998932,45.562241 
	C138.538361,43.945843 139.381180,43.060875 140.528351,42.009212 
	C140.832703,41.842518 140.957565,41.962132 140.986816,42.386017 
	C140.060211,44.827808 139.104340,46.845711 138.127014,48.882324 
	C138.105576,48.901035 138.061783,48.937382 138.061783,48.937382 
M121.292488,71.561890 
	C121.415855,72.177872 121.598007,73.338043 121.654167,73.331970 
	C124.906586,72.980583 128.659958,73.405518 131.265671,71.879044 
	C134.738342,69.844681 135.664291,64.288971 133.385162,62.196186 
	C128.747055,57.937283 133.623810,53.548065 130.100479,48.964790 
	C132.589615,49.872726 133.438751,50.182461 134.330124,50.507591 
	C134.448532,48.724449 134.529144,47.136715 134.663681,45.553566 
	C135.097427,40.449631 135.618469,35.352051 135.972168,30.242762 
	C136.190567,27.088232 134.756683,25.089140 131.400925,25.030809 
	C128.485596,24.980133 125.077065,24.210781 124.847786,28.878674 
	C124.467033,36.630363 121.894409,44.353024 124.759308,52.162376 
	C125.118309,53.140980 124.368187,54.591125 123.969437,55.767704 
	C123.390724,57.475304 122.296280,59.086792 122.079506,60.821457 
	C121.669884,64.099358 121.728561,67.435768 121.292488,71.561890 
z"
        />
        <path
          fill="#273593"
          opacity="1.000000"
          stroke="none"
          d="
M54.000923,22.026680 
	C54.000748,20.955763 54.000568,19.884848 54.265732,18.405611 
	C54.707520,17.989988 54.883965,17.982687 55.426003,17.975302 
	C56.522728,17.982733 57.253860,17.990248 58.219242,18.268951 
	C59.631317,18.697445 60.809147,18.854753 62.206059,19.290930 
	C65.994942,20.675787 69.564735,21.781776 73.134529,22.887764 
	C73.134529,22.887766 73.016502,22.978268 73.076675,23.276936 
	C74.116615,24.032917 75.096382,24.490229 76.076157,24.947540 
	C76.076157,24.947540 76.008484,25.000048 76.045013,25.314571 
	C77.649986,26.762644 79.218437,27.896193 80.786888,29.029743 
	C80.786888,29.029743 81.087326,29.238197 81.080215,29.839947 
	C81.061638,31.292620 81.050163,32.143543 80.776085,33.185753 
	C80.321068,33.986462 80.128662,34.595886 79.719505,35.159996 
	C79.502754,35.114681 79.068176,35.199970 78.924805,34.884094 
	C75.633736,33.554897 72.508316,32.687794 69.601944,35.977005 
	C67.046669,36.699387 64.894348,37.349617 62.391777,38.047619 
	C59.308804,39.369709 58.040775,38.347355 58.007469,35.484341 
	C57.977196,32.881912 58.220791,30.250660 57.898277,27.688166 
	C57.738850,26.421484 56.575867,25.281109 55.638252,24.061115 
	C55.409557,24.038649 55.010010,23.811520 54.988670,23.537958 
	C54.645195,22.851824 54.323059,22.439253 54.000923,22.026680 
z"
        />
        <path
          fill="#26338F"
          opacity="1.000000"
          stroke="none"
          d="
M9.593852,39.271809 
	C9.593852,39.271809 9.593606,39.088028 10.021322,38.988037 
	C13.003698,35.627048 15.548665,39.236557 18.079376,38.909184 
	C18.059551,38.919533 18.080059,38.885777 18.063740,39.263908 
	C19.700048,40.760799 21.352676,41.879559 23.002613,42.999237 
	C22.999924,43.000153 23.001215,42.995289 23.003658,43.372036 
	C25.722580,50.946423 25.722580,50.946423 19.625555,54.537148 
	C18.505554,54.565815 17.753735,54.499317 16.867157,54.113422 
	C15.802176,53.686966 14.871954,53.579910 13.941730,53.472855 
	C14.190830,53.776550 14.439930,54.080246 14.401187,54.559757 
	C13.901523,54.811035 13.689700,54.886490 13.222016,55.171585 
	C12.115435,56.892410 11.264716,58.403599 10.410784,59.920494 
	C8.992580,57.878513 7.994793,56.441864 6.997005,55.005215 
	C7.294056,50.946095 7.591107,46.886971 8.347390,42.320126 
	C9.069032,40.965538 9.331442,40.118671 9.593852,39.271809 
z"
        />
        <path
          fill="#424989"
          opacity="1.000000"
          stroke="none"
          d="
M489.862671,24.648256 
	C486.663147,24.611586 483.241974,24.214411 479.809662,23.981535 
	C465.224823,22.991985 453.779053,31.011063 451.024933,45.966335 
	C450.986786,47.108898 450.991974,47.544529 450.997192,47.980164 
	C450.997192,47.980164 450.948120,48.465164 450.728546,48.805241 
	C450.134308,49.401711 449.759644,49.658104 449.384979,49.914501 
	C448.556305,50.227459 447.118195,50.383045 446.990631,50.877045 
	C445.168213,57.934910 440.366608,59.441757 433.844360,58.550156 
	C430.808075,58.135086 427.667969,58.479221 423.136871,58.479221 
	C426.402771,53.886662 428.834534,50.467045 431.450684,47.386765 
	C431.164185,48.406681 430.512054,49.025703 430.256195,49.779278 
	C429.468750,52.098587 428.832947,54.469372 428.138184,56.820145 
	C430.624451,56.887737 433.111053,57.021515 435.596954,57.010155 
	C442.282593,56.979614 443.414825,56.007851 445.576385,48.596943 
	C444.057800,48.113247 442.535431,47.628338 441.013062,47.143436 
	C443.618774,46.885635 446.224487,46.627831 448.781525,46.374844 
	C457.178925,23.912540 470.274078,16.883759 489.862671,24.648256 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M296.988495,65.576126 
	C294.584778,65.649368 294.560822,60.363804 290.372192,62.995754 
	C289.999390,62.999199 289.992676,62.992409 289.995422,62.560444 
	C290.721649,56.868328 291.445099,51.608177 292.168243,46.350147 
	C290.195038,47.119663 287.430969,48.197590 284.713806,49.233917 
	C284.760681,49.192314 284.769775,49.317326 284.732605,49.032463 
	C284.397888,48.528812 284.100342,48.310017 283.836243,48.096336 
	C283.869720,48.101456 283.810089,48.136814 283.810089,48.136814 
	C283.972656,46.662693 284.135223,45.188576 284.322968,43.485992 
	C283.060974,43.135670 281.758179,42.774025 280.142059,42.325401 
	C285.350250,39.145027 287.675690,39.563374 296.718018,44.916214 
	C297.084167,44.931782 297.040375,44.949905 297.032654,45.318501 
	C297.626312,47.450878 298.227661,49.214661 298.829010,50.978443 
	C298.876129,51.147617 298.923248,51.316795 298.913177,51.743118 
	C294.735840,52.608200 292.841187,57.095741 295.102264,60.485157 
	C296.017670,61.857349 296.380402,63.598267 296.988495,65.576126 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M383.135223,60.618851 
	C382.737823,55.539700 382.414368,50.094166 382.328796,44.644894 
	C382.293488,42.395973 382.768982,40.139038 383.283752,37.204948 
	C383.703125,35.349483 383.850952,34.174824 383.998779,33.000168 
	C384.000031,32.341885 383.911102,31.668705 384.016968,31.027695 
	C384.780426,26.404854 383.109833,24.208075 378.210510,24.948332 
	C377.285095,25.088161 376.284790,24.732700 374.324951,24.475134 
	C373.549591,29.747051 372.783997,34.952717 372.008575,40.577515 
	C370.710327,40.681316 369.421844,40.365982 367.748230,39.902370 
	C368.673157,37.958771 370.403931,36.324883 371.172668,34.321793 
	C371.911194,32.397572 371.905640,30.075527 371.776489,27.955740 
	C371.559235,24.390549 372.690308,22.736794 376.537048,23.162262 
	C379.302979,23.468189 382.130005,23.221821 385.789886,23.221821 
	C385.494843,28.465693 385.213165,33.472317 384.906189,38.928066 
	C386.043335,38.988903 387.078888,39.044304 388.561249,39.183678 
	C389.266479,42.672871 389.524872,46.078094 389.692780,49.909431 
	C388.197327,47.052540 386.792358,43.769539 384.874023,39.286972 
	C384.287750,47.120415 383.796387,53.685684 383.135223,60.618851 
z"
        />
        <path
          fill="#2F3C91"
          opacity="1.000000"
          stroke="none"
          d="
M440.565308,47.082047 
	C442.535431,47.628338 444.057800,48.113247 445.576385,48.596943 
	C443.414825,56.007851 442.282593,56.979614 435.596954,57.010155 
	C433.111053,57.021515 430.624451,56.887737 428.138184,56.820145 
	C428.832947,54.469372 429.468750,52.098587 430.256195,49.779278 
	C430.512054,49.025703 431.164185,48.406681 431.815247,47.430809 
	C434.702789,47.097229 437.410187,47.058945 440.565308,47.082047 
z"
        />
        <path
          fill="#263595"
          opacity="1.000000"
          stroke="none"
          d="
M89.227524,45.018269 
	C89.486931,45.254421 89.610924,45.547855 89.358269,46.522682 
	C89.070961,47.426159 89.024910,47.705524 88.595535,47.990982 
	C84.568642,47.685818 83.532104,50.080624 82.677673,53.013283 
	C81.902809,53.022694 81.460030,53.027973 80.585793,53.021454 
	C78.427185,53.018059 76.700027,53.026459 74.608459,53.025894 
	C73.161774,52.005447 72.079498,50.993965 71.243378,49.818260 
	C71.647438,49.098785 71.805336,48.543533 72.111862,47.656479 
	C72.318855,46.408436 72.377228,45.492203 72.484657,44.566875 
	C72.533714,44.557785 72.573578,44.466312 72.858200,44.508057 
	C73.227264,44.223637 73.311707,43.897472 73.446556,43.533096 
	C73.496964,43.494881 73.556534,43.383278 73.844475,43.530563 
	C74.445503,43.123169 74.758591,42.568489 75.167732,41.794365 
	C75.663826,41.325451 76.063858,41.075985 76.792740,40.772903 
	C77.379333,40.377781 77.637070,40.036274 77.998360,39.478096 
	C78.326485,39.009083 78.606987,38.867733 78.957443,39.123596 
	C78.989136,39.600033 79.006805,39.790253 79.086403,40.344929 
	C81.764107,40.802841 84.379868,40.896294 87.036819,41.332619 
	C87.794502,42.789749 88.511009,43.904011 89.227524,45.018269 
M80.712418,47.928383 
	C79.871643,48.056911 79.030876,48.185440 78.190102,48.313965 
	C78.496971,49.106487 78.803841,49.899010 79.110710,50.691532 
	C79.708046,50.006302 80.305389,49.321072 80.712418,47.928383 
z"
        />
        <path
          fill="#2D3A90"
          opacity="1.000000"
          stroke="none"
          d="
M168.327087,70.747955 
	C169.386841,72.183075 170.446579,73.618202 172.224716,76.026184 
	C165.611145,76.026184 159.910217,76.251427 154.253983,75.848877 
	C153.023911,75.761330 151.813354,73.520607 150.847015,72.099434 
	C149.731689,70.459167 148.896744,68.628250 147.942352,66.466309 
	C147.944656,66.052490 148.079910,65.925079 148.429077,65.991943 
	C150.555084,68.192772 152.160126,70.513474 154.185837,72.376862 
	C155.109879,73.226845 156.864197,73.174232 158.241928,73.531013 
	C159.120132,69.624962 165.461029,67.296974 161.539215,61.952591 
	C161.009705,61.231033 161.105103,60.050907 160.911133,59.083122 
	C160.911133,59.083126 161.019760,58.981945 161.074188,58.931465 
	C163.482513,62.589787 165.836426,66.298592 167.829926,70.442368 
	C165.600723,71.882172 161.766647,68.674416 161.399063,73.791351 
	C164.402283,73.768280 167.732559,75.472000 168.327087,70.747955 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M81.017250,53.033257 
	C81.460030,53.027973 81.902809,53.022694 83.015701,53.209579 
	C84.120918,55.872719 84.407402,58.382969 85.046135,60.800137 
	C85.437218,62.280106 86.368774,63.617252 87.057610,65.018539 
	C85.681259,67.349541 84.304916,69.680550 82.640656,72.169312 
	C83.028900,68.704567 81.310349,67.997269 77.952667,67.877136 
	C71.933090,67.661758 65.944405,66.666634 59.924610,66.283493 
	C59.009644,66.225266 57.433334,67.538948 57.149883,68.507042 
	C56.606323,70.363487 56.771057,72.427330 56.304405,74.242149 
	C55.966881,69.702515 55.966881,65.325851 55.966881,60.318222 
	C61.454536,66.486633 72.507988,68.392609 83.538452,65.485023 
	C83.538452,63.095898 83.937943,60.524227 83.388283,58.174603 
	C83.059761,56.770252 81.244286,55.713749 80.214828,54.316895 
	C80.560722,53.766354 80.788986,53.399803 81.017250,53.033257 
z"
        />
        <path
          fill="#27348C"
          opacity="1.000000"
          stroke="none"
          d="
M176.596405,31.865482 
	C176.551666,31.470009 176.506927,31.074539 176.862839,30.221476 
	C177.547165,27.647795 177.830856,25.531708 178.114532,23.415621 
	C181.410980,23.366716 184.707443,23.317814 188.490509,23.743975 
	C188.651779,27.135801 188.326431,30.052561 188.001068,32.969322 
	C184.968552,33.003147 181.936035,33.036968 178.327972,32.822464 
	C177.367081,32.337917 176.981735,32.101700 176.596405,31.865482 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M18.099201,38.898834 
	C15.548665,39.236557 13.003698,35.627048 10.079828,38.917183 
	C12.870645,34.620449 16.030672,30.294579 19.515137,25.880066 
	C18.749184,27.909889 17.658791,30.028360 16.067039,33.120892 
	C19.557798,33.782421 22.790358,34.657166 26.074816,34.950165 
	C30.054688,35.305202 34.376358,34.039993 37.169018,38.392620 
	C37.529953,38.955173 39.002308,38.804642 40.446678,39.366390 
	C40.933533,41.465199 40.933533,43.182041 40.933533,44.898884 
	C35.017551,34.898464 25.941910,35.289955 16.268005,36.508595 
	C16.974499,37.430771 17.536850,38.164803 18.099201,38.898834 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M500.979797,40.410431 
	C501.444489,41.304546 502.016907,42.568512 501.940033,43.791676 
	C501.404755,52.310207 500.716797,60.819019 500.124146,69.334175 
	C500.012329,70.940552 500.108093,72.561386 500.108093,75.724586 
	C503.791779,71.883118 510.627258,77.947479 511.667389,69.294830 
	C511.923615,71.149780 511.923615,73.289330 511.923615,75.762215 
	C507.580414,75.762215 503.343658,75.762215 498.428680,75.762215 
	C499.213043,64.120941 500.000031,52.441166 500.979797,40.410431 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M6.925301,55.409340 
	C7.994793,56.441864 8.992580,57.878513 10.410784,59.920494 
	C11.264716,58.403599 12.115435,56.892410 13.157316,55.585304 
	C13.348478,59.168461 13.348478,62.547543 13.348478,66.229950 
	C20.288805,66.229950 26.658859,66.229950 33.028915,66.229950 
	C28.276184,66.807236 23.535921,67.604729 18.765797,67.874809 
	C16.128572,68.024132 14.705894,68.708694 14.707504,71.916969 
	C12.017057,66.836243 9.435327,61.324856 6.925301,55.409340 
z"
        />
        <path
          fill="#2D3A90"
          opacity="1.000000"
          stroke="none"
          d="
M147.877045,66.116211 
	C144.605682,60.662933 141.401917,55.145935 138.129974,49.283157 
	C138.061783,48.937382 138.105576,48.901035 138.409027,48.949944 
	C140.695984,49.072220 142.148346,49.343166 142.584030,51.960640 
	C143.337433,56.486752 145.035263,59.878757 151.050308,58.158131 
	C154.346298,57.215305 158.494568,62.723976 158.015030,68.189423 
	C155.025085,65.114471 152.718048,62.741871 149.851074,59.793392 
	C149.240753,61.906265 148.660339,63.915672 148.079910,65.925079 
	C148.079910,65.925079 147.944656,66.052490 147.877045,66.116211 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M553.112915,50.695091 
	C548.024353,38.770321 532.098633,35.066212 522.331177,43.790352 
	C519.945068,45.921635 519.220581,47.675110 522.783813,49.663685 
	C521.532166,49.917076 520.071472,49.917076 518.153442,49.917076 
	C518.208374,45.429134 519.780945,42.012043 523.807007,40.286144 
	C530.900330,37.245365 538.278687,36.695274 545.562317,39.445198 
	C550.408081,41.274715 553.529236,44.751682 553.112915,50.695091 
z"
        />
        <path
          fill="#E3E8F9"
          opacity="1.000000"
          stroke="none"
          d="
M187.051025,75.863518 
	C187.400208,63.874390 188.165298,51.926857 188.862732,39.534019 
	C189.365936,39.620895 189.936798,40.153080 190.763947,40.846718 
	C190.374741,52.354256 189.729233,63.700344 189.110794,75.481392 
	C188.580902,75.912605 188.023926,75.908859 187.051025,75.863518 
z"
        />
        <path
          fill="#3C458A"
          opacity="1.000000"
          stroke="none"
          d="
M478.547821,64.937729 
	C475.073883,68.937943 469.252258,68.643639 465.816986,64.205894 
	C461.470978,58.591530 462.007263,43.335709 467.143555,37.471306 
	C466.565094,43.980091 464.664459,50.480385 464.874725,56.911667 
	C465.137115,64.936676 470.329437,67.569351 478.547821,64.937729 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M512.323181,67.930176 
	C512.392395,58.355190 512.719238,48.610847 513.112976,38.869202 
	C513.135559,38.309662 513.702209,37.772110 514.385376,37.105919 
	C514.192322,44.511433 513.604797,52.033638 513.081055,59.560287 
	C512.917480,61.910767 513.423584,63.160553 515.794067,60.403397 
	C516.586487,63.990074 517.110413,67.832039 517.461914,72.064751 
	C515.724731,70.890808 514.160095,69.326111 512.323181,67.930176 
z"
        />
        <path
          fill="#3C458A"
          opacity="1.000000"
          stroke="none"
          d="
M488.358856,73.038773 
	C488.981354,71.869484 490.041443,70.727669 490.906097,69.453262 
	C492.760834,66.719505 494.514709,63.917316 497.042480,60.010975 
	C492.278564,60.010975 489.175507,59.834064 486.112854,60.108185 
	C485.059570,60.202461 484.081787,61.389965 483.115082,62.137005 
	C481.939362,63.045586 480.811768,64.016418 479.272217,64.934906 
	C481.258820,57.247551 487.437347,57.803513 493.512939,58.148766 
	C494.793182,58.221516 496.071167,58.333508 497.820435,58.461948 
	C497.277924,65.183716 494.252625,69.854897 488.358856,73.038773 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M515.025391,34.599442 
	C514.596252,33.404652 514.015747,31.815960 514.091736,30.259314 
	C514.260254,26.808475 513.803162,24.747074 509.384125,24.830042 
	C505.078644,24.910879 502.776154,25.914183 502.918518,30.612160 
	C502.980255,32.650688 502.360413,34.709866 501.692749,36.886189 
	C501.534668,32.580021 501.734009,28.147324 501.944916,23.457968 
	C506.716614,23.457968 510.954193,23.457968 515.809570,23.457968 
	C515.592896,27.115234 515.382385,30.667654 515.025391,34.599442 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M57.984993,17.997761 
	C57.253860,17.990248 56.522728,17.982733 55.205391,17.721558 
	C53.749664,17.321268 52.880150,17.174641 52.010632,17.028015 
	C51.256432,17.021446 50.502232,17.014877 48.966858,17.007011 
	C46.436752,17.327721 44.687820,17.649727 42.938885,17.971733 
	C37.333717,19.724726 31.731396,21.486919 26.120564,23.221586 
	C25.194319,23.507950 24.230799,23.673746 22.865644,23.838068 
	C33.252075,15.682064 45.445755,14.583448 58.512650,17.105314 
	C58.544487,17.768167 58.288464,17.922346 57.984993,17.997761 
z"
        />
        <path
          fill="#303C90"
          opacity="1.000000"
          stroke="none"
          d="
M87.271744,64.822510 
	C86.368774,63.617252 85.437218,62.280106 85.046135,60.800137 
	C84.407402,58.382969 84.120918,55.872719 83.347786,53.205444 
	C83.532104,50.080624 84.568642,47.685818 88.605835,48.437260 
	C88.818535,51.293804 88.638252,53.710217 88.456512,56.126518 
	C88.284737,58.410389 88.111427,60.694145 87.938782,62.977951 
	C87.787811,63.527462 87.636848,64.076973 87.271744,64.822510 
z"
        />
        <path
          fill="#3F4688"
          opacity="1.000000"
          stroke="none"
          d="
M554.891174,67.384842 
	C555.385376,69.494034 555.704773,71.955742 556.071228,74.779793 
	C558.815613,74.779793 561.545166,74.993805 564.224304,74.718201 
	C566.784058,74.454880 568.086304,73.104637 568.122314,70.074089 
	C568.176270,65.537270 569.003906,61.009647 569.834595,56.279877 
	C569.698669,62.509270 569.230713,68.936897 568.736572,75.724174 
	C564.091064,75.724174 559.528320,75.724174 554.290833,75.724174 
	C554.426758,73.172928 554.571533,70.455139 554.891174,67.384842 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M394.339417,85.889359 
	C394.608643,87.441917 394.608643,89.214363 394.608643,91.090416 
	C400.384918,93.178871 402.143188,92.480103 405.017853,87.028114 
	C405.923187,85.311050 407.138580,83.757439 408.548248,82.072876 
	C408.830902,82.341881 408.855988,82.707474 408.712494,82.986366 
	C401.767059,96.488586 407.007050,93.757584 390.542480,93.722420 
	C391.816406,90.973198 392.943298,88.541222 394.339417,85.889359 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M371.646362,42.964153 
	C371.658813,50.563381 371.347565,58.128117 370.945374,65.688019 
	C370.907501,66.400040 370.297211,67.081612 369.577728,67.887802 
	C369.372986,62.745075 369.543152,57.491871 369.388184,51.956161 
	C369.063019,48.807316 369.063019,45.940975 369.063019,42.652706 
	C369.289917,42.680691 370.293762,42.804508 371.646362,42.964153 
z"
        />
        <path
          fill="#424989"
          opacity="1.000000"
          stroke="none"
          d="
M456.067169,71.373817 
	C461.066895,72.402473 466.191101,73.868340 471.386719,75.008972 
	C473.244934,75.416924 475.275818,75.038582 477.915649,74.996475 
	C479.082886,74.668198 479.562347,74.362579 480.041809,74.056953 
	C481.189178,74.167191 482.336578,74.277435 483.731781,74.628372 
	C474.251526,77.943741 464.880005,77.911926 456.067169,71.373817 
z"
        />
        <path
          fill="#303C90"
          opacity="1.000000"
          stroke="none"
          d="
M86.995636,40.989750 
	C84.379868,40.896294 81.764107,40.802841 79.320404,40.178558 
	C79.664131,39.111523 79.835808,38.575317 80.159866,37.793415 
	C80.186913,36.766914 80.061584,35.986111 79.936264,35.205307 
	C80.128662,34.595886 80.321068,33.986462 81.069366,33.314880 
	C82.647308,33.507442 83.669350,33.762157 84.691399,34.016876 
	C84.691399,34.016876 85.030655,34.307278 85.052139,34.856804 
	C85.411987,35.925156 85.750366,36.443977 86.088737,36.962799 
	C86.088737,36.962799 86.007607,36.983860 86.036911,37.262154 
	C86.405312,38.015240 86.744408,38.490032 87.083511,38.964825 
	C87.083511,38.964825 87.005402,38.983879 87.011414,39.297840 
	C87.010155,40.071117 87.002892,40.530434 86.995636,40.989750 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M538.494446,73.295441 
	C539.369080,73.397812 540.173035,73.994659 541.064209,74.399635 
	C547.102905,77.143784 550.874451,74.773331 551.053772,68.347214 
	C551.147949,64.971024 551.654968,61.606354 552.343323,58.111423 
	C552.711060,63.791500 552.711060,69.596634 552.711060,76.066299 
	C548.165649,76.066299 544.244690,76.176331 540.341797,75.960075 
	C539.676270,75.923195 539.088074,74.489525 538.494446,73.295441 
z"
        />
        <path
          fill="#D1D7F2"
          opacity="1.000000"
          stroke="none"
          d="
M257.755646,56.772991 
	C257.817810,62.115574 257.585022,67.193092 257.182922,72.640045 
	C256.539276,72.739990 256.064941,72.470497 255.332794,72.110214 
	C255.470673,65.775902 255.866409,59.532391 256.495789,52.917324 
	C256.973206,53.866489 257.216980,55.187210 257.755646,56.772991 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M368.964233,70.471237 
	C370.289948,75.270432 374.443542,74.157143 377.957916,73.910675 
	C382.047028,73.623901 380.663300,70.228432 381.033813,67.832298 
	C381.172150,66.937698 381.660370,66.097198 382.348267,65.120361 
	C382.486664,68.526237 382.265839,72.043793 382.029816,75.803024 
	C377.594299,75.803024 373.360687,75.803024 368.856232,75.803024 
	C368.856232,74.120560 368.856232,72.520332 368.964233,70.471237 
z"
        />
        <path
          fill="#343B80"
          opacity="1.000000"
          stroke="none"
          d="
M316.486023,59.971619 
	C323.279358,59.226368 330.533569,58.560635 338.571533,57.822971 
	C338.738892,57.535690 339.426514,56.355331 340.450928,55.098465 
	C340.855377,55.472012 340.923065,55.922066 340.674622,56.915001 
	C340.246155,58.242237 340.133820,59.026592 340.021484,59.810944 
	C332.329956,59.891006 324.638458,59.971069 316.486023,59.971619 
z"
        />
        <path
          fill="#424989"
          opacity="1.000000"
          stroke="none"
          d="
M450.913361,57.030804 
	C451.882080,60.628769 452.850769,64.226738 453.765381,68.236038 
	C449.511597,64.105881 449.269928,58.364552 449.256348,52.615356 
	C450.067352,54.049625 450.490356,55.540215 450.913361,57.030804 
z"
        />
        <path
          fill="#3F4688"
          opacity="1.000000"
          stroke="none"
          d="
M570.013062,40.555210 
	C565.737000,40.730972 561.502319,40.473740 556.893921,40.145977 
	C561.031677,40.091034 565.543091,40.106625 570.013062,40.555210 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M539.797546,51.377224 
	C539.575806,51.901608 539.164062,53.463982 538.588867,53.526680 
	C535.469666,53.866688 532.309998,53.836109 529.070801,53.609207 
	C532.495544,52.764500 536.013855,52.243763 539.797546,51.377224 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M72.951553,22.595432 
	C69.564735,21.781776 65.994942,20.675787 62.216843,19.013937 
	C62.018005,18.273621 62.027473,18.089165 62.036942,17.904709 
	C65.614159,19.370838 69.191368,20.836969 72.951553,22.595432 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M356.183075,64.661118 
	C355.261414,60.744282 353.196655,56.221588 358.680725,53.046921 
	C358.143616,56.776806 357.244019,60.541542 356.183075,64.661118 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M88.164986,62.668930 
	C88.111427,60.694145 88.284737,58.410389 88.456512,56.126518 
	C88.638252,53.710217 88.818535,51.293804 88.989151,48.431168 
	C89.024910,47.705524 89.070961,47.426159 89.239792,46.738014 
	C89.038780,51.672794 88.714981,57.016354 88.164986,62.668930 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M404.076355,50.554829 
	C402.712830,47.320000 401.354553,43.684074 399.991211,39.658905 
	C401.351288,42.897690 402.716431,46.525711 404.076355,50.554829 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M395.613647,67.611046 
	C399.751190,71.548088 400.622437,75.634598 397.839905,79.596024 
	C396.852844,75.752335 396.246368,71.878990 395.613647,67.611046 
z"
        />
        <path
          fill="#2A3176"
          opacity="1.000000"
          stroke="none"
          d="
M484.041656,38.004181 
	C487.610687,38.320915 491.180756,38.823780 494.747955,38.804226 
	C495.500977,38.800098 496.243500,36.882397 497.219360,35.432659 
	C497.447845,35.018921 497.892151,35.024677 497.892151,35.024677 
	C498.064758,36.482082 498.237335,37.939491 498.487885,40.055134 
	C493.721100,40.055134 489.318420,40.055134 484.477112,39.622070 
	C484.039551,38.794064 484.040619,38.399120 484.041656,38.004181 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M525.417114,50.000450 
	C527.202026,48.316311 529.340759,46.631027 531.617065,45.158482 
	C532.550476,44.554680 533.780884,44.410164 534.945740,44.318214 
	C534.096863,45.262104 532.622131,45.777187 532.357727,46.656300 
	C531.294922,50.189762 528.765198,50.409996 525.417114,50.000450 
z"
        />
        <path
          fill="#3F4688"
          opacity="1.000000"
          stroke="none"
          d="
M555.930847,52.396408 
	C556.064026,55.311394 556.025024,58.581028 555.694824,61.929138 
	C555.522034,58.922100 555.640320,55.836578 555.930847,52.396408 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M390.572449,53.618305 
	C392.001099,56.749279 393.457947,60.266357 394.866882,64.227837 
	C393.412872,61.116299 392.006744,57.560352 390.572449,53.618305 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M409.406921,81.179840 
	C410.351562,78.404556 411.564270,75.385979 413.159180,72.394951 
	C412.252594,75.260506 410.963776,78.098526 409.406921,81.179840 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M521.683594,75.475319 
	C524.762024,75.162498 528.137268,75.120056 531.884521,75.214684 
	C528.831238,75.483063 525.405823,75.614380 521.683594,75.475319 
z"
        />
        <path
          fill="#585E98"
          opacity="1.000000"
          stroke="none"
          d="
M188.410431,33.018272 
	C188.326431,30.052561 188.651779,27.135801 188.935822,23.806633 
	C191.888367,26.641872 190.379990,29.854746 188.410431,33.018272 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M360.458984,52.174515 
	C362.399384,49.725346 364.794312,47.734318 367.988647,51.429302 
	C365.613983,51.901234 363.225739,52.009533 360.458984,52.174515 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M525.619019,53.924248 
	C523.547363,55.286465 521.099182,56.624508 518.272522,57.942947 
	C520.343567,56.582249 522.792969,55.241158 525.619019,53.924248 
z"
        />
        <path
          fill="#364285"
          opacity="1.000000"
          stroke="none"
          d="
M422.596100,39.166489 
	C420.772644,40.107403 418.527313,40.966438 415.948914,41.932907 
	C416.196075,37.491833 419.735382,39.509071 422.596100,39.166489 
z"
        />
        <path
          fill="#585E98"
          opacity="1.000000"
          stroke="none"
          d="
M177.752350,23.446964 
	C177.830856,25.531708 177.547165,27.647795 176.973175,29.940449 
	C176.918625,27.904112 177.154388,25.691208 177.752350,23.446964 
z"
        />
        <path
          fill="#364285"
          opacity="1.000000"
          stroke="none"
          d="
M427.301575,43.870663 
	C427.014069,42.710663 427.002991,41.381149 426.985168,39.653221 
	C430.033112,39.556702 430.033112,39.556702 427.301575,43.870663 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M80.682304,28.728952 
	C79.218437,27.896193 77.649986,26.762644 76.064163,25.276306 
	C77.557098,26.091730 79.067413,27.259947 80.682304,28.728952 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M397.225220,79.854469 
	C396.754364,81.642799 396.029419,83.648781 394.922485,85.674927 
	C395.350739,83.820763 396.160919,81.946442 397.225220,79.854469 
z"
        />
        <path
          fill="#2F3C91"
          opacity="1.000000"
          stroke="none"
          d="
M450.942474,56.583549 
	C450.490356,55.540215 450.067352,54.049625 449.400574,52.320152 
	C449.147644,51.591747 449.138519,51.102222 449.257202,50.263603 
	C449.759644,49.658104 450.134308,49.401711 450.713684,49.048523 
	C450.936127,51.346588 450.953857,53.741440 450.942474,56.583549 
z"
        />
        <path
          fill="#2F3C91"
          opacity="1.000000"
          stroke="none"
          d="
M497.936249,34.675751 
	C497.892151,35.024677 497.447845,35.018921 497.225769,35.013885 
	C496.359741,33.577408 495.715851,32.145962 495.254028,30.369946 
	C495.436096,30.025372 495.876648,30.019081 495.876648,30.019077 
	C496.577881,31.454990 497.279114,32.890907 497.936249,34.675751 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M17.735268,75.813881 
	C19.024761,76.480988 20.457718,77.426193 21.913265,78.732956 
	C20.583485,78.093666 19.231110,77.092827 17.735268,75.813881 
z"
        />
        <path
          fill="#3F4688"
          opacity="1.000000"
          stroke="none"
          d="
M556.374512,44.384117 
	C556.650879,45.603111 556.789429,47.223152 556.605103,48.931442 
	C556.267029,47.608181 556.251770,46.196674 556.374512,44.384117 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M84.593903,33.718719 
	C83.669350,33.762157 82.647308,33.507442 81.331978,33.123596 
	C81.050163,32.143543 81.061638,31.292620 81.121109,30.018152 
	C82.278198,30.869928 83.387299,32.145248 84.593903,33.718719 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M538.637817,45.530376 
	C541.116943,45.263107 542.141846,46.327080 540.527710,48.936478 
	C539.732178,47.951397 539.250122,46.901974 538.637817,45.530376 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M298.905609,50.608467 
	C298.227661,49.214661 297.626312,47.450878 297.043274,45.298168 
	C297.701782,46.685658 298.342010,48.462074 298.905609,50.608467 
z"
        />
        <path
          fill="#3C458A"
          opacity="1.000000"
          stroke="none"
          d="
M484.020813,37.637589 
	C484.040619,38.399120 484.039551,38.794064 484.029846,39.484993 
	C483.082825,38.077423 482.144409,36.373867 481.063171,34.362213 
	C481.946899,35.126411 482.973419,36.198704 484.020813,37.637589 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M87.036819,41.332619 
	C87.002892,40.530434 87.010155,40.071117 87.038071,39.267754 
	C87.804855,40.701931 88.550987,42.480156 89.262329,44.638325 
	C88.511009,43.904011 87.794502,42.789749 87.036819,41.332619 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M25.125397,81.324783 
	C26.465492,81.626221 27.994839,82.205605 29.545788,83.147430 
	C28.149811,82.874161 26.732229,82.238449 25.125397,81.324783 
z"
        />
        <path
          fill="#243289"
          opacity="1.000000"
          stroke="none"
          d="
M61.791786,17.684742 
	C62.027473,18.089165 62.018005,18.273621 61.997757,18.735069 
	C60.809147,18.854753 59.631317,18.697445 58.219242,18.268950 
	C58.288464,17.922346 58.544487,17.768167 58.856163,17.333420 
	C59.821720,17.242668 60.684174,17.353722 61.791786,17.684742 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M534.720825,74.358551 
	C535.308899,73.639183 536.227295,72.829994 537.422302,72.069580 
	C536.816284,72.835152 535.933777,73.551949 534.720825,74.358551 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M29.935513,83.774673 
	C31.008005,83.844795 32.323830,84.135445 33.799156,84.754227 
	C32.698723,84.719971 31.438784,84.357590 29.935513,83.774673 
z"
        />
        <path
          fill="#364285"
          opacity="1.000000"
          stroke="none"
          d="
M425.306519,47.894836 
	C425.456757,46.771118 425.881958,45.456638 426.672302,44.081184 
	C426.552155,45.248165 426.066803,46.476124 425.306519,47.894836 
z"
        />
        <path
          fill="#364285"
          opacity="1.000000"
          stroke="none"
          d="
M415.373291,42.481422 
	C415.251099,43.705070 414.845764,45.163792 414.045227,46.713726 
	C414.130066,45.442123 414.610107,44.079308 415.373291,42.481422 
z"
        />
        <path
          fill="#3C458A"
          opacity="1.000000"
          stroke="none"
          d="
M471.386292,34.055836 
	C472.172058,33.501938 473.302338,32.899372 474.693451,32.510891 
	C473.879761,33.152374 472.805298,33.579769 471.386292,34.055836 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M414.775391,70.155090 
	C414.810120,69.289856 415.074341,68.217094 415.715759,67.097885 
	C415.730255,68.016808 415.367584,68.982185 414.775391,70.155090 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M75.935333,24.664871 
	C75.096382,24.490229 74.116615,24.032917 73.117760,23.214951 
	C73.997292,23.363600 74.895897,23.872902 75.935333,24.664871 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M72.361633,81.040527 
	C72.826355,80.399582 73.605865,79.720787 74.751724,79.065247 
	C74.304199,79.726562 73.490311,80.364616 72.361633,81.040527 
z"
        />
        <path
          fill="#364285"
          opacity="1.000000"
          stroke="none"
          d="
M413.464844,46.925949 
	C413.426544,47.772697 413.142487,48.807812 412.513214,49.912136 
	C412.518341,49.025669 412.868683,48.069992 413.464844,46.925949 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M78.813194,76.317551 
	C79.105942,75.635315 79.660446,74.823944 80.591927,74.000870 
	C80.337593,74.722260 79.706268,75.455338 78.813194,76.317551 
z"
        />
        <path
          fill="#3C458A"
          opacity="1.000000"
          stroke="none"
          d="
M478.323761,32.922234 
	C478.997467,32.994343 479.955200,33.216930 480.931854,33.759682 
	C480.169769,33.744137 479.388794,33.408424 478.323761,32.922234 
z"
        />
        <path
          fill="#424989"
          opacity="1.000000"
          stroke="none"
          d="
M454.089172,69.296524 
	C454.644226,69.483307 455.273407,69.959679 455.920715,70.762512 
	C455.346985,70.588028 454.755157,70.087074 454.089172,69.296524 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M395.672852,39.190262 
	C395.368500,39.448368 394.732788,39.626583 394.047058,39.537403 
	C394.445190,39.216793 394.893311,39.163582 395.672852,39.190262 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M518.728027,73.964874 
	C519.330139,73.941490 520.100342,74.156242 520.930664,74.731476 
	C520.292603,74.795639 519.594421,74.499321 518.728027,73.964874 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M9.349249,39.489182 
	C9.331442,40.118671 9.069032,40.965538 8.448321,41.911743 
	C8.428229,41.242912 8.766438,40.474731 9.349249,39.489182 
z"
        />
        <path
          fill="#424989"
          opacity="1.000000"
          stroke="none"
          d="
M492.533112,26.364052 
	C491.870758,26.311878 491.021790,25.988384 490.113831,25.286575 
	C490.818726,25.303083 491.582581,25.697908 492.533112,26.364052 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M75.371292,79.054634 
	C75.594727,78.485313 76.110664,77.849182 76.978134,77.272644 
	C76.774368,77.884087 76.219086,78.435959 75.371292,79.054634 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M389.499573,50.631149 
	C389.936371,51.043106 390.398651,51.785763 390.755798,52.909828 
	C390.275452,52.514774 389.900269,51.738316 389.499573,50.631149 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M340.354767,59.733330 
	C340.133820,59.026592 340.246155,58.242237 340.570129,57.244846 
	C340.750549,57.906448 340.719299,58.781082 340.354767,59.733330 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M517.645447,57.996452 
	C517.532349,58.567974 517.140442,59.244370 516.433594,59.982567 
	C516.534668,59.396687 516.950623,58.749008 517.645447,57.996452 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M532.540771,75.385986 
	C532.712952,75.014992 533.182922,74.627449 533.987976,74.354675 
	C533.828186,74.769447 533.333374,75.069443 532.540771,75.385986 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M68.362495,83.140617 
	C68.539497,82.798637 69.024971,82.443733 69.833954,82.110695 
	C69.661964,82.464272 69.166466,82.795982 68.362495,83.140617 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M70.379791,82.152908 
	C70.535172,81.806931 70.991371,81.432854 71.764450,81.058067 
	C71.614418,81.413170 71.147514,81.768990 70.379791,82.152908 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M22.050545,79.331802 
	C22.474527,79.289246 23.041626,79.506393 23.687897,80.033821 
	C23.242599,80.093231 22.718132,79.842361 22.050545,79.331802 
z"
        />
        <path
          fill="#D1D7F2"
          opacity="1.000000"
          stroke="none"
          d="
M256.867004,49.045681 
	C256.551880,48.783539 256.334259,48.199680 256.161652,47.302094 
	C256.459259,47.566895 256.711884,48.145432 256.867004,49.045681 
z"
        />
        <path
          fill="#424989"
          opacity="1.000000"
          stroke="none"
          d="
M495.851990,29.781031 
	C495.876648,30.019081 495.436096,30.025372 495.215790,30.022739 
	C494.838379,29.828815 494.661469,29.648993 494.538849,29.437679 
	C494.510162,29.388227 494.665558,29.231968 494.915771,29.049934 
	C495.438263,29.036793 495.682281,29.226110 495.851990,29.781031 
z"
        />
        <path
          fill="#585E98"
          opacity="1.000000"
          stroke="none"
          d="
M176.509125,32.155445 
	C176.981735,32.101700 177.367081,32.337917 177.876236,32.770653 
	C177.473984,32.793247 176.947906,32.619328 176.509125,32.155445 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M87.053741,38.660690 
	C86.744408,38.490032 86.405312,38.015240 86.064926,37.230988 
	C86.383751,37.399872 86.703865,37.878212 87.053741,38.660690 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M413.748871,72.184494 
	C413.629822,71.721230 413.736420,71.078949 414.210571,70.423759 
	C414.376923,70.942390 414.175720,71.473938 413.748871,72.184494 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M86.055634,36.645897 
	C85.750366,36.443977 85.411987,35.925156 85.088211,35.077160 
	C85.409386,35.274990 85.715958,35.801994 86.055634,36.645897 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M81.260880,73.861984 
	C81.247063,73.431183 81.504868,72.893517 82.075287,72.314209 
	C82.102776,72.766747 81.817642,73.260925 81.260880,73.861984 
z"
        />
        <path
          fill="#323A83"
          opacity="1.000000"
          stroke="none"
          d="
M394.786957,65.287094 
	C395.136841,65.505203 395.483490,66.049072 395.756104,66.936905 
	C395.382629,66.724861 395.083160,66.168854 394.786957,65.287094 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M77.538391,77.292511 
	C77.478584,77.039268 77.660934,76.725273 78.136421,76.458900 
	C78.322182,76.845757 78.105850,77.087509 77.538391,77.292511 
z"
        />
        <path
          fill="#3C458A"
          opacity="1.000000"
          stroke="none"
          d="
M467.761078,37.322227 
	C467.646454,37.078079 467.786194,36.699760 468.246887,36.259438 
	C468.383728,36.527637 468.199585,36.857849 467.761078,37.322227 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M14.695671,72.610352 
	C14.945209,72.525185 15.262864,72.674881 15.561396,73.110977 
	C15.200024,73.330002 14.940530,73.145958 14.695671,72.610352 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M20.704914,24.969299 
	C20.758850,25.202583 20.542042,25.482328 20.055241,25.808239 
	C20.001554,25.574854 20.217861,25.295307 20.704914,24.969299 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M23.841652,80.524002 
	C24.042732,80.392326 24.375071,80.491837 24.838245,80.828796 
	C24.637022,80.962570 24.304966,80.858871 23.841652,80.524002 
z"
        />
        <path
          fill="#3F478D"
          opacity="1.000000"
          stroke="none"
          d="
M517.672607,72.915909 
	C517.898865,72.818291 518.190979,72.866440 518.370850,73.165428 
	C518.042908,73.360085 517.869568,73.241890 517.672607,72.915909 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M15.691332,73.689728 
	C15.930771,73.591354 16.253489,73.711700 16.560707,74.116867 
	C16.216805,74.349052 15.959939,74.184639 15.691332,73.689728 
z"
        />
        <path
          fill="#3C458A"
          opacity="1.000000"
          stroke="none"
          d="
M486.345337,74.010223 
	C486.342865,73.759735 486.657471,73.493820 487.291992,73.214882 
	C487.295441,73.466179 486.978912,73.730492 486.345337,74.010223 
z"
        />
        <path
          fill="#2D3A91"
          opacity="1.000000"
          stroke="none"
          d="
M578.611206,39.722641 
	C578.581177,39.876358 578.411194,39.925842 578.104492,39.975693 
	C578.058716,39.757286 578.226562,39.638069 578.611206,39.722641 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M16.683382,74.685944 
	C16.926212,74.596481 17.245935,74.723465 17.530186,75.135483 
	C17.177576,75.350342 16.932764,75.177635 16.683382,74.685944 
z"
        />
        <path
          fill="#F1F4FD"
          opacity="1.000000"
          stroke="none"
          d="
M326.726929,48.289650 
	C327.259552,49.877029 327.603546,51.105312 328.065918,52.756279 
	C323.797211,52.756279 319.968750,52.756279 314.403442,52.756279 
	C316.773682,49.899998 318.049622,47.119846 320.121216,46.262798 
	C321.751221,45.588428 324.362762,47.286369 326.726929,48.289650 
z"
        />
        <path
          fill="#2F3C91"
          opacity="1.000000"
          stroke="none"
          d="
M451.294769,47.947456 
	C450.991974,47.544529 450.986786,47.108898 450.994080,46.346947 
	C451.520935,46.249306 452.035278,46.477989 452.549622,46.706676 
	C452.230530,47.109367 451.911438,47.512058 451.294769,47.947456 
z"
        />
        <path
          fill="#2F3C91"
          opacity="1.000000"
          stroke="none"
          d="
M479.739746,74.058792 
	C479.562347,74.362579 479.082886,74.668198 478.306458,74.989922 
	C478.485596,74.690895 478.961639,74.375763 479.739746,74.058792 
z"
        />
        <path
          fill="#F7F8FC"
          opacity="1.000000"
          stroke="none"
          d="
M79.719513,35.159996 
	C80.061584,35.986111 80.186913,36.766914 79.918991,37.826180 
	C79.192299,38.238140 78.998192,38.482388 78.943428,38.837379 
	C78.606987,38.867733 78.326485,39.009083 77.695862,39.575882 
	C77.014496,40.202400 76.739197,40.514458 76.463890,40.826515 
	C76.063858,41.075985 75.663826,41.325451 74.910370,41.869690 
	C74.223473,42.570732 73.890007,42.977005 73.556534,43.383278 
	C73.556534,43.383278 73.496964,43.494881 73.250061,43.618706 
	C72.757019,43.922684 72.613823,44.163944 72.573578,44.466312 
	C72.573578,44.466312 72.533714,44.557785 72.191635,44.713497 
	C70.765251,47.416084 69.680939,49.962955 68.596634,52.509830 
	C70.733948,53.178173 72.871254,53.846516 75.424408,54.616390 
	C77.259232,54.645580 78.678215,54.573235 80.097198,54.500889 
	C81.244286,55.713749 83.059761,56.770252 83.388283,58.174603 
	C83.937943,60.524227 83.538452,63.095898 83.538452,65.485023 
	C72.507988,68.392609 61.454536,66.486633 55.966881,60.318222 
	C55.966881,65.325851 55.966881,69.702515 56.034115,74.539429 
	C56.101349,74.999672 55.953438,75.084839 55.778198,75.234344 
	C55.330135,75.519249 55.105156,75.710846 54.928020,75.958641 
	C54.928020,75.958641 54.455193,75.864258 54.009048,75.846100 
	C53.562904,75.827942 53.111309,75.947235 52.723068,75.828201 
	C48.536026,75.548752 44.737228,75.388329 40.938423,75.227905 
	C40.931385,72.461349 40.924347,69.694786 40.942398,66.108047 
	C40.967491,63.820969 40.967491,62.354065 40.967491,59.923599 
	C39.037170,62.132568 37.900970,63.432789 36.764771,64.733009 
	C36.764771,64.733009 36.898457,64.877281 36.550034,64.838440 
	C35.491329,65.207458 34.781044,65.615318 34.070763,66.023178 
	C33.891396,66.028915 33.712029,66.034645 33.280792,66.135162 
	C26.658859,66.229950 20.288805,66.229950 13.348478,66.229950 
	C13.348478,62.547543 13.348478,59.168461 13.413178,55.375664 
	C13.689700,54.886490 13.901523,54.811035 14.668610,54.696236 
	C15.816556,54.582199 16.409237,54.507507 17.001917,54.432819 
	C17.753735,54.499317 18.505554,54.565815 20.054892,54.655212 
	C23.288015,54.060577 25.723621,53.443050 28.159225,52.825520 
	C27.193251,50.169270 26.332441,47.466850 25.198622,44.884289 
	C24.848484,44.086765 23.754576,43.615784 23.001215,42.995289 
	C23.001215,42.995289 22.999924,43.000153 22.852734,42.709106 
	C21.163715,41.240635 19.621887,40.063206 18.080059,38.885777 
	C18.080059,38.885777 18.059551,38.919533 18.079376,38.909184 
	C17.536850,38.164803 16.974499,37.430771 16.268005,36.508595 
	C25.941910,35.289955 35.017551,34.898464 40.933533,44.898884 
	C40.933533,43.182041 40.933533,41.465199 40.821625,39.364990 
	C40.773777,37.921680 40.837837,36.861729 40.914047,35.076241 
	C40.850597,33.892544 40.774994,33.434391 40.699390,32.976234 
	C40.793663,30.319870 40.887932,27.663507 41.487381,24.549549 
	C46.331711,23.998476 50.670860,23.904999 55.010010,23.811520 
	C55.010010,23.811520 55.409557,24.038649 55.677177,24.521345 
	C55.944801,31.661371 55.944801,38.318703 55.944801,45.907719 
	C58.720959,42.677948 60.731495,40.338894 62.742031,37.999840 
	C64.894348,37.349617 67.046669,36.699387 70.033997,36.059258 
	C73.602074,35.779560 76.335121,35.489765 79.068176,35.199970 
	C79.068176,35.199970 79.502754,35.114681 79.719513,35.159996 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M37.107681,64.825294 
	C37.900970,63.432789 39.037170,62.132568 40.967491,59.923599 
	C40.967491,62.354065 40.967491,63.820969 40.865578,65.657394 
	C39.659306,65.657135 38.554947,65.287354 37.107681,64.825294 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M40.947525,75.443054 
	C44.737228,75.388329 48.536026,75.548752 52.266552,75.866318 
	C49.493332,76.100426 46.788391,76.177399 44.083447,76.254364 
	C43.616859,76.237061 43.150272,76.219757 42.107204,76.160706 
	C41.530724,76.118950 41.062504,76.075638 41.062504,76.075638 
	C41.062504,76.075638 40.956619,75.658195 40.947525,75.443054 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M44.391647,76.484016 
	C46.788391,76.177399 49.493332,76.100426 52.654793,75.985352 
	C53.111309,75.947235 53.562904,75.827942 53.778675,75.917145 
	C50.896248,76.242126 47.798046,76.477898 44.391647,76.484016 
z"
        />
        <path
          fill="#474F94"
          opacity="1.000000"
          stroke="none"
          d="
M34.412579,66.031105 
	C34.781044,65.615318 35.491329,65.207458 36.510651,64.748329 
	C36.131260,65.144379 35.442825,65.591705 34.412579,66.031105 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M41.125671,76.225586 
	C41.062504,76.075638 41.530724,76.118950 41.757374,76.180000 
	C41.984024,76.241051 41.605648,76.519440 41.605648,76.519440 
	C41.605648,76.519440 41.188835,76.375526 41.125671,76.225586 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M55.151840,75.910164 
	C55.105156,75.710846 55.330135,75.519249 55.812935,75.312294 
	C55.881332,75.525024 55.665581,75.732010 55.151840,75.910164 
z"
        />
        <path
          fill="#E9EDF7"
          opacity="1.000000"
          stroke="none"
          d="
M539.146667,60.881607 
	C537.778442,63.598801 536.870178,66.389664 534.965759,68.008957 
	C533.986084,68.842003 530.489624,68.234451 529.491211,67.090385 
	C528.531433,65.990662 528.645752,62.978100 529.485413,61.474892 
	C531.612183,57.667496 537.070190,57.327366 539.146667,60.881607 
z"
        />
        <path
          fill="#F4F5FB"
          opacity="1.000000"
          stroke="none"
          d="
M282.393066,67.412537 
	C275.792816,69.774216 272.791351,67.975746 272.172211,60.890640 
	C271.946198,58.304058 272.276764,55.578953 272.853607,53.030247 
	C274.176910,47.183304 277.253174,45.716816 283.457764,47.966240 
	C283.810089,48.136814 283.869720,48.101456 283.903015,48.366249 
	C284.214111,48.859798 284.491943,49.088558 284.769775,49.317322 
	C284.769775,49.317326 284.760681,49.192314 284.775330,49.629387 
	C286.736450,55.472778 285.846069,60.698158 283.844696,65.890839 
	C283.906006,65.932915 283.833191,65.803238 283.582153,65.929352 
	C283.028229,66.516335 282.725342,66.977203 282.413330,67.445251 
	C282.404175,67.452431 282.393066,67.412537 282.393066,67.412537 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M290.003357,63.003357 
	C289.920471,64.151070 289.833649,65.294624 289.740204,66.525368 
	C291.066895,66.892097 292.409424,67.263206 295.406799,68.091759 
	C292.288696,70.497765 290.325928,73.131088 287.939392,73.597939 
	C284.126953,74.343719 280.032898,73.650200 276.175568,73.567711 
	C278.765717,71.341011 280.648010,69.722809 282.461700,67.758568 
	C282.393066,67.412537 282.404175,67.452431 282.757782,67.501648 
	C283.351990,66.968323 283.592590,66.385780 283.833191,65.803238 
	C283.833191,65.803238 283.906006,65.932915 284.217957,65.887360 
	C286.350800,64.891998 288.171753,63.942204 289.992676,62.992409 
	C289.992676,62.992409 289.999390,62.999199 290.003357,63.003357 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M277.037842,45.743923 
	C276.570496,45.206112 276.381897,44.562557 276.193268,43.919006 
	C276.599915,43.803253 277.006592,43.687504 277.413239,43.571751 
	C277.381012,44.260559 277.348785,44.949371 277.037842,45.743923 
z"
        />
        <path
          fill="#2D3A90"
          opacity="1.000000"
          stroke="none"
          d="
M383.728546,33.233269 
	C383.850952,34.174824 383.703125,35.349483 383.289337,36.759247 
	C383.168335,35.818359 383.313324,34.642365 383.728546,33.233269 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M54.988670,23.537958 
	C50.670860,23.904999 46.331711,23.998476 41.533817,24.109402 
	C42.290920,23.423952 43.454529,22.257814 44.733379,22.113604 
	C47.482376,21.803602 50.290749,22.020054 53.537868,22.023842 
	C54.323059,22.439253 54.645195,22.851824 54.988670,23.537958 
z"
        />
        <path
          fill="#243289"
          opacity="1.000000"
          stroke="none"
          d="
M43.348618,17.981892 
	C44.687820,17.649727 46.436752,17.327721 48.589756,17.011250 
	C47.248669,17.341873 45.503510,17.666964 43.348618,17.981892 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M40.435562,33.024456 
	C40.774994,33.434391 40.850597,33.892544 40.820164,34.687157 
	C40.533329,34.373302 40.352531,33.722992 40.435562,33.024456 
z"
        />
        <path
          fill="#243289"
          opacity="1.000000"
          stroke="none"
          d="
M52.179119,17.268307 
	C52.880150,17.174641 53.749664,17.321268 54.839798,17.721640 
	C54.883965,17.982687 54.707520,17.989988 54.266281,18.004215 
	C53.450195,17.843628 52.898899,17.676113 52.179119,17.268307 
z"
        />
        <path
          fill="#2E3A91"
          opacity="1.000000"
          stroke="none"
          d="
M148.429077,65.991943 
	C148.660339,63.915672 149.240753,61.906265 149.851074,59.793392 
	C152.718048,62.741871 155.025085,65.114471 158.015030,68.189423 
	C158.494568,62.723976 154.346298,57.215305 151.050308,58.158131 
	C145.035263,59.878757 143.337433,56.486752 142.584030,51.960640 
	C142.148346,49.343166 140.695984,49.072220 138.430450,48.931232 
	C139.104340,46.845711 140.060211,44.827808 141.048935,42.446159 
	C142.414673,41.248070 143.673462,40.245628 145.097443,39.618023 
	C147.338791,38.630169 149.653793,37.458847 152.014267,37.255665 
	C153.031113,37.168129 154.239609,39.306992 156.021729,41.098640 
	C153.665924,41.610756 152.504257,41.648735 151.568146,42.127628 
	C150.647217,42.598751 149.955109,43.517155 149.162598,44.239296 
	C152.748764,46.550575 149.435486,52.596222 154.819656,54.256260 
	C156.907303,54.899918 158.444504,57.328857 160.571808,59.013817 
	C161.105103,60.050907 161.009705,61.231033 161.539215,61.952591 
	C165.461029,67.296974 159.120132,69.624962 158.241928,73.531013 
	C156.864197,73.174232 155.109879,73.226845 154.185837,72.376862 
	C152.160126,70.513474 150.555084,68.192772 148.429077,65.991943 
M144.260880,46.392105 
	C144.197327,45.722706 144.133774,45.053310 144.070221,44.383915 
	C143.578339,44.699863 143.086456,45.015812 142.594574,45.331760 
	C143.068787,45.846375 143.542984,46.360989 144.260880,46.392105 
z"
        />
        <path
          fill="#2E3A91"
          opacity="1.000000"
          stroke="none"
          d="
M121.442566,71.154877 
	C121.728561,67.435768 121.669884,64.099358 122.079506,60.821457 
	C122.296280,59.086792 123.390724,57.475304 123.969437,55.767704 
	C124.368187,54.591125 125.118309,53.140980 124.759308,52.162376 
	C121.894409,44.353024 124.467033,36.630363 124.847786,28.878674 
	C125.077065,24.210781 128.485596,24.980133 131.400925,25.030809 
	C134.756683,25.089140 136.190567,27.088232 135.972168,30.242762 
	C135.618469,35.352051 135.097427,40.449631 134.663681,45.553566 
	C134.529144,47.136715 134.448532,48.724449 134.330124,50.507591 
	C133.438751,50.182461 132.589615,49.872726 130.100479,48.964790 
	C133.623810,53.548065 128.747055,57.937283 133.385162,62.196186 
	C135.664291,64.288971 134.738342,69.844681 131.265671,71.879044 
	C128.659958,73.405518 124.906586,72.980583 121.654167,73.331970 
	C121.598007,73.338043 121.415855,72.177872 121.442566,71.154877 
z"
        />
        <path
          fill="#343C85"
          opacity="1.000000"
          stroke="none"
          d="
M62.391777,38.047615 
	C60.731495,40.338894 58.720959,42.677948 55.944801,45.907719 
	C55.944801,38.318703 55.944801,31.661371 55.905876,24.543812 
	C56.575867,25.281109 57.738850,26.421484 57.898277,27.688166 
	C58.220791,30.250660 57.977196,32.881912 58.007469,35.484341 
	C58.040775,38.347355 59.308804,39.369709 62.391777,38.047615 
z"
        />
        <path
          fill="#303C90"
          opacity="1.000000"
          stroke="none"
          d="
M78.924805,34.884094 
	C76.335121,35.489765 73.602074,35.779560 70.436958,35.987103 
	C72.508316,32.687794 75.633736,33.554897 78.924805,34.884094 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M23.003658,43.372036 
	C23.754576,43.615784 24.848484,44.086765 25.198622,44.884289 
	C26.332441,47.466850 27.193251,50.169270 28.159225,52.825520 
	C25.723621,53.443050 23.288015,54.060577 20.423075,54.560043 
	C25.722580,50.946423 25.722580,50.946423 23.003658,43.372036 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M18.063740,39.263908 
	C19.621887,40.063206 21.163715,41.240635 22.855423,42.708191 
	C21.352676,41.879559 19.700048,40.760799 18.063740,39.263908 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M16.867157,54.113422 
	C16.409237,54.507507 15.816556,54.582199 14.956453,54.520416 
	C14.439930,54.080246 14.190830,53.776550 13.941730,53.472855 
	C14.871954,53.579910 15.802176,53.686966 16.867157,54.113422 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M289.995422,62.560444 
	C288.171753,63.942204 286.350800,64.891998 284.156616,65.845276 
	C285.846069,60.698158 286.736450,55.472778 284.728455,49.670990 
	C287.430969,48.197590 290.195038,47.119663 292.168243,46.350147 
	C291.445099,51.608177 290.721649,56.868328 289.995422,62.560444 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M284.732605,49.032459 
	C284.491943,49.088558 284.214111,48.859798 283.869507,48.361130 
	C284.100342,48.310017 284.397888,48.528812 284.732605,49.032459 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M75.008568,54.514858 
	C72.871254,53.846516 70.733948,53.178173 68.596634,52.509830 
	C69.680939,49.962955 70.765251,47.416084 72.142578,44.722588 
	C72.377228,45.492203 72.318855,46.408436 71.873894,47.827957 
	C71.323944,48.881657 71.160583,49.432072 70.997223,49.982487 
	C72.079498,50.993965 73.161774,52.005447 74.618683,53.272041 
	C75.018829,53.856075 75.023918,54.185310 75.008568,54.514858 
z"
        />
        <path
          fill="#303C90"
          opacity="1.000000"
          stroke="none"
          d="
M80.807571,48.282112 
	C80.305389,49.321072 79.708046,50.006302 79.110710,50.691532 
	C78.803841,49.899010 78.496971,49.106487 78.190102,48.313965 
	C79.030876,48.185440 79.871643,48.056911 80.807571,48.282112 
z"
        />
        <path
          fill="#303C90"
          opacity="1.000000"
          stroke="none"
          d="
M75.424408,54.616390 
	C75.023918,54.185310 75.018829,53.856075 74.983093,53.281006 
	C76.700027,53.026459 78.427185,53.018059 80.585793,53.021458 
	C80.788986,53.399803 80.560722,53.766354 80.214828,54.316895 
	C78.678215,54.573235 77.259232,54.645580 75.424408,54.616390 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M73.844475,43.530563 
	C73.890007,42.977005 74.223473,42.570732 74.814308,42.089134 
	C74.758591,42.568489 74.445503,43.123169 73.844475,43.530563 
z"
        />
        <path
          fill="#303C90"
          opacity="1.000000"
          stroke="none"
          d="
M71.243378,49.818260 
	C71.160583,49.432072 71.323944,48.881657 71.725266,48.159760 
	C71.805336,48.543533 71.647438,49.098785 71.243378,49.818260 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M78.957443,39.123596 
	C78.998192,38.482388 79.192299,38.238140 79.766617,38.071877 
	C79.835808,38.575317 79.664131,39.111523 79.258469,39.814098 
	C79.006805,39.790253 78.989136,39.600033 78.957443,39.123596 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M72.858200,44.508057 
	C72.613823,44.163944 72.757019,43.922684 73.199661,43.656921 
	C73.311707,43.897472 73.227264,44.223637 72.858200,44.508057 
z"
        />
        <path
          fill="#3D468F"
          opacity="1.000000"
          stroke="none"
          d="
M76.792740,40.772900 
	C76.739197,40.514458 77.014496,40.202400 77.592300,39.792557 
	C77.637070,40.036274 77.379333,40.377781 76.792740,40.772900 
z"
        />
        <path
          fill="#2E3A91"
          opacity="1.000000"
          stroke="none"
          d="
M168.223175,70.836082 
	C167.732559,75.472000 164.402283,73.768280 161.399063,73.791351 
	C161.766647,68.674416 165.600723,71.882172 167.873779,70.790222 
	C168.278046,70.703110 168.119278,70.924210 168.223175,70.836082 
z"
        />
        <path
          fill="#2E3A90"
          opacity="1.000000"
          stroke="none"
          d="
M283.582153,65.929352 
	C283.592590,66.385780 283.351990,66.968323 282.766907,67.494461 
	C282.725342,66.977203 283.028229,66.516335 283.582153,65.929352 
z"
        />
        <path
          fill="#2D3A90"
          opacity="1.000000"
          stroke="none"
          d="
M144.139038,46.633858 
	C143.542984,46.360989 143.068787,45.846375 142.594574,45.331760 
	C143.086456,45.015812 143.578339,44.699863 144.070221,44.383911 
	C144.133774,45.053310 144.197327,45.722706 144.139038,46.633858 
z"
        />
      </svg>
    )}
  </ButtonBase>
);

export default LogoSection;
