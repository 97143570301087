import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper
} from '@mui/material';
import { format } from 'date-fns';
import { EyeFilled } from '@ant-design/icons';

// Define the type for your data
export type SavedSimulation = {
  name: string;
  id: string;
  per: string;
  lvl: string;
  pid: string;
  cat: string;
  rgn: string;
  brd: string;
  subBrd: string;
  scha: string;
  ct_on: string;
  ct_by: string;
};

// Define your props type
export type SavedSimulationProps = {
  simulations: SavedSimulation[];
  handleRowSelection: (simulationId: string) => any;
};

type Order = 'asc' | 'desc';

const SavedSimulationTable = ({ simulations, handleRowSelection }: SavedSimulationProps) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof SavedSimulation>('per');

  const handleSort = (property: keyof SavedSimulation) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const allSimulatioins = simulations;
  const sortedSimulations = [...allSimulatioins]?.sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '74vh' }} >
      <Table size="small" sx={{
        "& .MuiTableRow-root:hover": {
          backgroundColor: "#d2eafc",
          cursor: 'pointer',
        }
      }}>
        <TableHead sx={{ position: "sticky", top: 1 }}>
          <TableRow sx={{
            "& th": {
              color: "#000",
              backgroundColor: "#e3e3e3"
            }
          }}>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'name'}
                direction={orderBy === 'name' ? order : 'asc'}
                onClick={() => handleSort('name')}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'pid'}
                direction={orderBy === 'pid' ? order : 'asc'}
                onClick={() => handleSort('pid')}
              >
                PID
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'cat'}
                direction={orderBy === 'cat' ? order : 'asc'}
                onClick={() => handleSort('cat')}
              >
                Category
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'rgn'}
                direction={orderBy === 'rgn' ? order : 'asc'}
                onClick={() => handleSort('rgn')}
              >
                Region
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'scha'}
                direction={orderBy === 'scha' ? order : 'asc'}
                onClick={() => handleSort('scha')}
              >
                Channel
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'brd'}
                direction={orderBy === 'brd' ? order : 'asc'}
                onClick={() => handleSort('brd')}
              >
                Brand
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'subBrd'}
                direction={orderBy === 'subBrd' ? order : 'asc'}
                onClick={() => handleSort('subBrd')}
              >
                Sub_Brand
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'ct_on'}
                direction={orderBy === 'ct_on' ? order : 'asc'}
                onClick={() => handleSort('ct_on')}
              >
                Created_On
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                sx={{ fontSize: '10px' }}
                active={orderBy === 'ct_by'}
                direction={orderBy === 'ct_by' ? order : 'asc'}
                onClick={() => handleSort('ct_by')}
              >
                Created_By
              </TableSortLabel>
            </TableCell>
            <TableCell>
              {/* leave this for action icon */}
              <></>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedSimulations.map((simulation) => (
            <TableRow key={simulation.id} onClick={(event) => handleRowSelection(simulation.id)} >
              <TableCell sx={{ fontSize: '12px' }}>{simulation.name}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>{simulation.pid}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>{simulation.cat}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>{simulation.rgn}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>{simulation.scha}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>{simulation.brd}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>{simulation.subBrd}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>{format(new Date(simulation.ct_on), 'dd-MMM-yyyy')}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>{simulation.ct_by}</TableCell>
              <TableCell sx={{ fontSize: '12px' }}>
                <EyeFilled style={{ fontSize: '18px', fontWeight: 'bold' }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SavedSimulationTable;

