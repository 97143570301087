import { useEffect } from 'react';
import { useSelector } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from 'utils/route-guard/common';
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const { loginState } = useSelector((state) => state.dashboard);
  const location = useLocation();

  useEffect(() => {
    let authToken = localStorage.getItem('authToken');
    if (!authToken) {
      auth.token.getWithRedirect({
        responseType: ['token', 'id_token'],
        state: 'defaultrandomstring'
      });
    }
  }, [navigate, location, loginState]);

  return children;
};

export default AuthGuard;
