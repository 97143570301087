
import { getCommonRoutes } from './CommonRoutes';
import { getPrivilegedRoutes } from './PrivilegedRoutes';

// ==============================|| MAIN ROUTING ||============================== //
export function getMainRoutes() {
  //concating common and privilaged routes here 
  return [...getCommonRoutes(), ...getPrivilegedRoutes()]
}
const MainRoutes = () => {
  const mainRoutes = getMainRoutes();
  return mainRoutes;
}

export default MainRoutes;
