
export function getCustomLegends(
  yAxisData: any,
  fromSimulator: boolean,
  simulation: any,
  scenarioColors: string[],
  activeIndex0: boolean,
  activeIndex1: boolean,
  activeIndex2: boolean) {
  const legendItems: string[] = [];
  if (fromSimulator) {
    if (yAxisData.length === 7) {
      // Case for 7 series items (3 scenarios)
      if (activeIndex0 && activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[0]}" stroke-width="2" />         
            </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
           </svg> ${simulation.scenarios[0].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[1]}" stroke-width="2" />   
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[2]}" stroke-width="2" />   
            </svg> ${simulation.scenarios[2].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[2]}" />
            </svg> ${simulation.scenarios[2].sn} - Revenue`
        );
      }
      else if (!activeIndex0 && activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[1]}" stroke-width="2" />   
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[2]}" stroke-width="2" />   
            </svg> ${simulation.scenarios[2].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[2]}" />
            </svg> ${simulation.scenarios[2].sn} - Revenue`
        );
      }
      else if (!activeIndex0 && !activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[2]}" stroke-width="2" />   
            </svg> ${simulation.scenarios[2].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[2]}" />
            </svg> ${simulation.scenarios[2].sn} - Revenue`
        );
      }
      else if (activeIndex0 && !activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[0]}" stroke-width="2" />         
            </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
           </svg> ${simulation.scenarios[0].sn} - Revenue`,
        );
      }
      else if (!activeIndex0 && activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[1]}" stroke-width="2" />   
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`
        )
      }
      else if (activeIndex0 && activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[0]}" stroke-width="2" />         
            </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
           </svg> ${simulation.scenarios[0].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[1]}" stroke-width="2" />   
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`,
        );
      }
      else if (activeIndex0 && !activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[0]}" stroke-width="2" />         
            </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
           </svg> ${simulation.scenarios[0].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[2]}" stroke-width="2" />   
            </svg> ${simulation.scenarios[2].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[2]}" />
            </svg> ${simulation.scenarios[2].sn} - Revenue`
        );
      }

    } else if (yAxisData.length === 5) {
      // Case for 5 series items (2 scenarios)
      if (activeIndex0 && activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[0]}" stroke-width="2" />        
            </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[0]}" />
            </svg> ${simulation.scenarios[0].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[1]}" stroke-width="2" />       
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`
        );
      }
      else if (!activeIndex0 && activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[1]}" stroke-width="2" />       
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="${scenarioColors[1]}" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`
        );

      }
      else if (activeIndex0 && !activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
           <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
          </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[0]}" stroke-width="2" />        
          </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <rect width="12" height="12" fill="${scenarioColors[0]}" />
          </svg> ${simulation.scenarios[0].sn} - Revenue`,
        );
      }

    } else if (yAxisData.length === 3) {
      // Case for 3 series items (1 scenario)
      legendItems.push(
        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
          </svg> Forecasted Volume`,
        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
           <line x1="0" y1="8" x2="12" y2="8" stroke="${scenarioColors[0]}" stroke-width="2" />        
          </svg> ${simulation.scenarios[0].sn} - Volume`,
        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <rect width="12" height="12" fill="${scenarioColors[0]}" />
          </svg> ${simulation.scenarios[0].sn} - Revenue`
      );
    }
  }
  else {
    if (yAxisData.length === 7) {
      // Case for 7 series items (3 scenarios)  
      if (activeIndex0 && activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="#df8617" stroke-width="2" />        
              </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <rect width="12" height="12" fill="#df8617" />
              </svg> ${simulation.scenarios[0].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="#292FF2" stroke-width="2" />       
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#292FF2" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                 <line x1="0" y1="8" x2="12" y2="8" stroke="#3db002" stroke-width="2" />   
            </svg> ${simulation.scenarios[2].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#3db002" />
            </svg> ${simulation.scenarios[2].sn} - Revenue`
        )
      }
      else if (!activeIndex0 && activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
           </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                    <line x1="0" y1="8" x2="12" y2="8" stroke="#292FF2" stroke-width="2" />       
                 </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                   <rect width="12" height="12" fill="#292FF2" />
                 </svg> ${simulation.scenarios[1].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                      <line x1="0" y1="8" x2="12" y2="8" stroke="#3db002" stroke-width="2" />   
                 </svg> ${simulation.scenarios[2].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                   <rect width="12" height="12" fill="#3db002" />
                 </svg> ${simulation.scenarios[2].sn} - Revenue`)

      }
      else if (!activeIndex0 && !activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
           </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                      <line x1="0" y1="8" x2="12" y2="8" stroke="#3db002" stroke-width="2" />   
                 </svg> ${simulation.scenarios[2].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                   <rect width="12" height="12" fill="#3db002" />
                 </svg> ${simulation.scenarios[2].sn} - Revenue`)

      }
      else if (activeIndex0 && !activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
           </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="#df8617" stroke-width="2" />        
              </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <rect width="12" height="12" fill="#df8617" />
              </svg> ${simulation.scenarios[0].sn} - Revenue`);
      }
      else if (!activeIndex0 && activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
            </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
           <line x1="0" y1="8" x2="12" y2="8" stroke="#292FF2" stroke-width="2" />       
        </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <rect width="12" height="12" fill="#292FF2" />
        </svg> ${simulation.scenarios[1].sn} - Revenue`);
      }
      else if (activeIndex0 && !activeIndex1 && activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
        </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
         <line x1="0" y1="8" x2="12" y2="8" stroke="#df8617" stroke-width="2" />        
        </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <rect width="12" height="12" fill="#df8617" />
        </svg> ${simulation.scenarios[0].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
           <line x1="0" y1="8" x2="12" y2="8" stroke="#3db002" stroke-width="2" />   
        </svg> ${simulation.scenarios[2].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
        <rect width="12" height="12" fill="#3db002" />
        </svg> ${simulation.scenarios[2].sn} - Revenue`);
      }
      else if (activeIndex0 && activeIndex1 && !activeIndex2) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="#df8617" stroke-width="2" />        
              </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <rect width="12" height="12" fill="#df8617" />
              </svg> ${simulation.scenarios[0].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="#292FF2" stroke-width="2" />       
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#292FF2" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`
        )
      }
    } else if (yAxisData.length === 5) {
      // Case for 5 series items (2 scenarios)
      if (activeIndex0 && activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="#df8617" stroke-width="2" />        
              </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <rect width="12" height="12" fill="#df8617" />
              </svg> ${simulation.scenarios[0].sn} - Revenue`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="#292FF2" stroke-width="2" />       
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#292FF2" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`
        );
      }
      else if (!activeIndex0 && activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
              </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
               <line x1="0" y1="8" x2="12" y2="8" stroke="#292FF2" stroke-width="2" />       
            </svg> ${simulation.scenarios[1].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <rect width="12" height="12" fill="#292FF2" />
            </svg> ${simulation.scenarios[1].sn} - Revenue`
        );
      }
      else if (activeIndex0 && !activeIndex1) {
        legendItems.push(
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
          </svg> Forecasted Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
             <line x1="0" y1="8" x2="12" y2="8" stroke="#df8617" stroke-width="2" />        
          </svg> ${simulation.scenarios[0].sn} - Volume`,
          `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <rect width="12" height="12" fill="#df8617" />
          </svg> ${simulation.scenarios[0].sn} - Revenue`,
        );
      }

    } else if (yAxisData.length === 3) {
      // Case for 3 series items (1 scenario)
      legendItems.push(
        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
           <line x1="0" y1="8" x2="16" y2="8" stroke="#000" stroke-width="2" stroke-dasharray="4 2" />
        </svg> Forecasted Volume`,
        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
         <line x1="0" y1="8" x2="12" y2="8" stroke="#df8617" stroke-width="2" />        
        </svg> ${simulation.scenarios[0].sn} - Volume`,
        `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
          <rect width="12" height="12" fill="#df8617" />
        </svg> ${simulation.scenarios[0].sn} - Revenue`
      );
    }
  }
  return legendItems;
}