import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Popper,
  Paper,
  Box,
  MenuItem,
  Select,
  Typography,
  Stack,
} from '@mui/material';
import { MultiSelector } from '../../../components/common/Filter/MultiSelector'; // Adjust the import path as necessary
import { CloseOutlined } from '@ant-design/icons';
import CustomButton from '../Common/CustomButton';

interface FilterPopperProps {
  anchorEl: any | null;
  open: boolean;
  handleClose: () => void;
  onFiltersChange: (filters: SelectedFilters) => void;
  filtersData: any;
}

interface SelectedFilters {
  level: string;
  regions: string[];
  channels: string[];
  categories: string[];
  brands: string[];
  subBrands: string[];
}
const getUniqueValues = (items: any[], key: string): string[] =>
  [...new Set(items?.map(item => item[key]))];

const FilterPopper: React.FC<FilterPopperProps> = ({
  anchorEl,
  open,
  handleClose,
  onFiltersChange,
  filtersData
}) => {
  const [level, setLevel] = useState<string>('sub_brand');
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedSubBrands, setSelectedSubBrands] = useState<string[]>([]);
  const [filteredChannels, setFilteredChannels] = useState<string[]>();
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
  const [filteredBrands, setFilteredBrands] = useState<string[]>([]);
  const [filteredSubBrands, setFilteredSubBrands] = useState<string[]>([]);

  useEffect(() => {
    setFilteredCategories(getUniqueValues(filtersData, 'cat'));
    setFilteredChannels(getUniqueValues(filtersData, 'scha'));
    setFilteredBrands(getUniqueValues(filtersData, 'brd'));
    setFilteredSubBrands(getUniqueValues(filtersData, 'subBrd'));
  }, [filtersData]);

  useEffect(() => {
    let filteredData = filtersData;

    if (selectedRegions.length > 0) {
      filteredData = filteredData.filter((item: any) => selectedRegions.includes(item.rgn));
    }

    setFilteredChannels(getUniqueValues(filteredData, 'scha'));
    setFilteredBrands(getUniqueValues(filteredData, 'brd'));

    if (selectedChannels.length > 0) {
      filteredData = filteredData.filter((item: any) => selectedChannels.includes(item.scha));
    }

    setFilteredCategories(getUniqueValues(filteredData, 'cat'));
    setFilteredSubBrands(getUniqueValues(filteredData, 'subBrd'));
  }, [selectedRegions, selectedChannels, selectedBrands]);

  const handleLevelChange = (event: any) => {
    setLevel(event.target.value);
  };

  const handleRegionsChange = (id: string, value: string[]) => {
    setSelectedRegions(value);
    // Clear channels, categories, brands, and subBrands when regions are changed
    setSelectedChannels([]);
    setSelectedCategories([]);
    setSelectedBrands([]);
    setSelectedSubBrands([]);
  };

  const handleChannelsChange = (id: string, value: string[]) => {
    setSelectedChannels(value);
    // Clear categories, brands, and subBrands when channels are changed
    setSelectedCategories([]);
    setSelectedBrands([]);
    setSelectedSubBrands([]);
  };

  const handleCategoriesChange = (id: string, value: string[]) => {
    setSelectedCategories(value);
  };

  const handleBrandsChange = (id: string, value: string[]) => {
    setSelectedBrands(value);
    // Clear subBrands when brands are changed
    setSelectedSubBrands([]);
  };

  const handleSubBrandsChange = (id: string, value: string[]) => {
    setSelectedSubBrands(value);
  };

  const handleClear = () => {
    setLevel('sub_brand');
    setSelectedRegions([]);
    setSelectedChannels([]);
    setSelectedCategories([]);
    setSelectedBrands([]);
    setSelectedSubBrands([]);
  };

  const handleOk = () => {
    const selectedFilters: SelectedFilters = {
      level,
      regions: selectedRegions,
      channels: selectedChannels,
      categories: selectedCategories,
      brands: selectedBrands,
      subBrands: selectedSubBrands,
    };
    onFiltersChange(selectedFilters);
    handleClose();
  };

  return (
    <Popper open={open} anchorEl={anchorEl.current} placement="bottom-start" sx={{ zIndex: 99 }}>
      {({ TransitionProps }) => (
        <Paper {...TransitionProps} elevation={3} sx={{ pl: 2, pr: 2, pb: 2, width: 250, border: '1px solid black', borderRadius: '10px' }}>
          <Box display="flex" justifyContent="space-between" padding={0}>
            <Typography variant='h5' paddingTop={1}>Filters</Typography>
            <IconButton onClick={handleClose} sx={{ padding: 0 }}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="body2">Level</Typography>
            <Select
              value={level}
              onChange={handleLevelChange}
              fullWidth
              style={{ height: 30, fontSize: '10px' }}
            >
              <MenuItem value="sub_brand" style={{ fontSize: '12px' }}>Sub Brand</MenuItem>
              <MenuItem value="PPG" style={{ fontSize: '12px' }}>PPG</MenuItem>
            </Select>
          </Box>
          <Box mt={2}>
            <MultiSelector
              id='Region'
              label="Region"
              options={getUniqueValues(filtersData, 'rgn')}
              selectedValues={selectedRegions}
              handleChange={(id: any, val: any) => handleRegionsChange(id, val)}
            />
          </Box>

          <Box mt={1}>
            <MultiSelector
              id='Channel'
              label="Channel"
              options={filteredChannels}
              selectedValues={selectedChannels}
              handleChange={(id: any, val: any) => handleChannelsChange(id, val)}
            />
          </Box>

          <Box mt={1}>
            <MultiSelector
              id='Category'
              label="Category"
              options={filteredCategories}
              selectedValues={selectedCategories}
              handleChange={(id: any, val: any) => handleCategoriesChange(id, val)}
            />
          </Box>

          <Box mt={1}>
            <MultiSelector
              id='Brand'
              label="Brand"
              options={filteredBrands}
              selectedValues={selectedBrands}
              handleChange={(id: any, val: any) => handleBrandsChange(id, val)}
            />
          </Box>

          <Box mt={1}>
            <MultiSelector
              id='SubBrand'
              label="Sub Brand"
              options={filteredSubBrands}
              selectedValues={selectedSubBrands}
              handleChange={(id: any, val: any) => handleSubBrandsChange(id, val)}
            />
          </Box>


          <Stack mt={2} height={26} direction="row" justifyContent="end" spacing={.5}>
            <CustomButton text="Clear" onClick={handleClear} />
            <CustomButton text="OK" onClick={handleOk} />
          </Stack>
        </Paper>
      )}
    </Popper>
  );
};

export default FilterPopper;