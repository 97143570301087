import { Button } from "@mui/material";

interface ButtonProps {
    text: string;
    onClick: () => void;
    endIcon?: any;
    startIcon?: any;
    disabled?: boolean;
}

const CustomButton = ({ onClick, text, endIcon, startIcon, disabled }: ButtonProps) => {
    return <Button
        focusRipple={false}
        disabled={disabled}
        size='small'
        variant='outlined'
        endIcon={endIcon}
        startIcon={startIcon}
        sx={{
            ':hover': {
                bgcolor: '#262828', // theme.palette.primary.main
                color: 'white',
                borderColor: 'black'
            },
            color: 'black',
            fontWeight: 'bold',
            borderRadius: '20px',
            pl: 1, pr: 1,
            border: '1px solid black'
        }}
        onClick={onClick}
    >
        {text}
    </Button>
}

export default CustomButton;