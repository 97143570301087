import { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SavedSimulationsView from 'pages/priceelasticitySimulator/SavedSimulationsView';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const PriceElasticity = Loadable(lazy(() => import('pages/priceelasticity/priceelasticity')));
const SimulatorHost = Loadable(lazy(() => import('pages/priceelasticitySimulator/SimulatorHost')));
const ProductSearchView = Loadable(lazy(() => import('pages/priceelasticitySimulator/ProductSearchView')));
const PriceElasticitySimulator = Loadable(lazy(() => import('pages/priceelasticitySimulator/SimulationWizard')));

const LoginCallback = Loadable(lazy(() => import('pages/Login/callback')));

export function getCommonRoutes() {
  return [
    {
      path: '/login',
      element: <MainLayout />,
      children: [
        {
          path: 'callback',
          element: <LoginCallback />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <DashboardDefault />
        },
        {
          path: '/dashboard',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: '/priceelasticity',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/priceelasticity',
          element: <PriceElasticity />
        }
      ]
    },
    {
      path: '/priceelasticitysimulator',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <SimulatorHost />
        },
        {
          path: 'product-search',
          element: <ProductSearchView />
        },
        {
          path: 'simulation-wizard',
          element: <PriceElasticitySimulator />
        },
        {
          path: 'saved-simulations',
          element: <SavedSimulationsView />
        }
      ]
    },
  ];
}


