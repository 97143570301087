// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell-center {
  text-align: center;
}
.cell-center > * {
  margin: 0 auto;
}

.cell-right {
  text-align: right;
}
.cell-right > * {
  margin: 0 0 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/assets/third-party/react-table.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".cell-center {\r\n  text-align: center;\r\n}\r\n.cell-center > * {\r\n  margin: 0 auto;\r\n}\r\n\r\n.cell-right {\r\n  text-align: right;\r\n}\r\n.cell-right > * {\r\n  margin: 0 0 0 auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
