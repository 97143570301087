import { createSlice } from '@reduxjs/toolkit';
import { getFormattedDate, getPreviousMonthLastDay, currentDate } from 'components/date/utils/utils';

import { DateFilterProps } from 'types/datefilter';

const initialState: DateFilterProps = {
  dateRangeType: 'yearToDate',
  calenderView: 'yearToDate',
  selectedYear: '',
  selectedQuarter: '',
  selectedMonth: '',
  selectedRange: {
    startDate: `${new Date().getFullYear()}-01-01`,
    endDate: new Date().getMonth() === 0 ? currentDate : getFormattedDate(getPreviousMonthLastDay(), 'yyyy-mm-dd'),
    key: 'selection',
  },
  dateRangeSelected: {
    fromDate: `${new Date().getFullYear()}-01-01`,
    toDate: new Date().getMonth() === 0 ? currentDate : getFormattedDate(getPreviousMonthLastDay(), 'yyyy-mm-dd')
  }
};

const dateRange = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    selectYear(state, action) {
      state.selectedYear = action.payload;
    },
    selectQuarter(state, action) {
      state.selectedQuarter = action.payload;
    },
    selectMonth(state, action) {
      state.selectedMonth = action.payload;
    },
    selectRange(state, action) {
      const { startDate, endDate } = action.payload;
      state.selectedRange = { startDate, endDate, key: 'selection' };
    },
    dateRangeSelect(state, action) {
      const { fromDate, toDate } = action.payload;
      state.dateRangeSelected = { fromDate, toDate };
    },
    updateRangeType(state, action) {
      state.dateRangeType = action.payload;
    },
    updateCalenderView(state, action) {
      state.calenderView = action.payload;
    }
  }
});

export default dateRange.reducer;

export const { selectYear, selectQuarter, selectMonth, selectRange, dateRangeSelect, updateRangeType, updateCalenderView } =
  dateRange.actions;
