import { createSlice } from '@reduxjs/toolkit';

import {
  ProductLevelFilterProps,
  SellInFilterProps,
  SellOutFilterProps,
  NISFilterProps,
  MarketShareByChannelFilterProps
} from 'types/filter';

// initial Sell In state
export const initialSellInState: SellInFilterProps = {
  category: [],
  brand: [],
  subBrand: [],
  salesChannel: [],
  salesOffice: [],
  level: 'category'
};

export const initialSellOutState: SellOutFilterProps = {
  category: [],
  brand: [],
  subBrand: [],
  level: 'category'
};

// initial NIS state
export const initialNISState: NISFilterProps = {
  category: [],
  manufacturer: [],
  brand: [],
  subBrand: [],
  region: [],
  channel: [],
  level: 'category'
};

// initial MarketShareByChannel state
export const initialMarketShareByChannelState: MarketShareByChannelFilterProps = {
  level: 'Manufacturer',
  category: '',
  tier: [],
  source: false,
  volume: false,
  segment: [],
  manufacturer: [],
  brand: [],
  subBrand: [],
  count: [],
  dayNight: [],
  wingNoWing: [],
  covering: [],
  size: [],
  packagingType: [],
  subSegment: []
};

export const closeFilter: boolean = false;

// initial state
export const initialState: ProductLevelFilterProps = {
  sellIn: initialSellInState,
  NIS: initialNISState,
  sellOut: initialSellOutState,
  MktShareChannel: initialMarketShareByChannelState,
  closeFilter: closeFilter
};

const productLevelFilters = createSlice({
  name: 'productLevelFilters',
  initialState,
  reducers: {
    setSellInFilters(state, action) {
      state.sellIn = action.payload;
      // Object.assign(state, action.payload);
    },
    setSellInLevelFilter(state, action) {
      state.sellIn.level = action.payload;
    },
    setNISFilters(state, action) {
      state.NIS = action.payload;
    },
    setNISLevelFilter(state, action) {
      state.NIS.level = action.payload;
    },
    setSellOutFilters(state, action) {
      state.sellOut = action.payload;
    },
    setSellOutLevelFilter(state, action) {
      state.sellOut.level = action.payload;
    },
    setMktShareChannelLevelFilter(state, action) {
      state.MktShareChannel.level = action.payload;
    },
    setMktShareChannelCategoryFilter(state, action) {
      state.MktShareChannel.category = action.payload;
    },
    setMktShareChannelFilter(state, action) {
      state.MktShareChannel = action.payload;
    },
    setCloseFilter(state, action) {
      state.closeFilter = action.payload;
    }
  }
});

export default productLevelFilters.reducer;

export const {
  setSellOutFilters,
  setSellOutLevelFilter,
  setSellInFilters,
  setSellInLevelFilter,
  setNISFilters,
  setNISLevelFilter,
  setMktShareChannelLevelFilter,
  setMktShareChannelCategoryFilter,
  setMktShareChannelFilter,
  setCloseFilter
} = productLevelFilters.actions;
