import { createSlice } from '@reduxjs/toolkit';

import { UserPreferenceProps } from 'types/userPrefrence';

const initialState: UserPreferenceProps = {
  source: 'Lotus Scan Track',
  scanTrack: {
    level: 'SUB_BRAND',
    region: [],
    channel: [],
    associatedChannel: [],
  },
  retailIndex: {
    level: 'SUB_BRAND',
    region: [],
    channel: [],
    associatedChannel: [],
  },
  columnsToHide: ['subSeg', 'id', 'selection']
};

const userPrefrence = createSlice({
  name: 'userPrefrence',
  initialState,
  reducers: {
    setSource(state, action){
      state.source = action.payload;
    },
    setLevel(state, action) {
      if(state.source === 'Lotus Scan Track'){
        state.scanTrack.level = action.payload;
      }else{
        state.retailIndex.level = action.payload;
      }
    },
    setRegion(state, action) {
      if(state.source === 'Lotus Scan Track'){
        state.scanTrack.region = action.payload;
      }else{
        state.retailIndex.region = action.payload;
      }
    },
    setChannel(state, action) {
      if(state.source === 'Lotus Scan Track'){
        state.scanTrack.channel = action.payload;
      }else{
        state.retailIndex.channel = action.payload;
      }
    },
    // setTier(state, action) {
    //   state.tier = action.payload;
    // },
    setColumnsToHide(state, action) {
      state.columnsToHide = action.payload;
    },
    setAssociatedChannel(state, action) {
      if(state.source === 'Lotus Scan Track'){
        state.scanTrack.associatedChannel = action.payload;
      }else{
        state.retailIndex.associatedChannel = action.payload;
      }
    }
  }
});

export default userPrefrence.reducer;

export const { setSource, setLevel, setRegion, setChannel, setColumnsToHide, setAssociatedChannel } = userPrefrence.actions; // setTier, to be added back
