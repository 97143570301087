// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import dashboard from './dashboard';
import dateRange from './datefilter';
import userPrefrence from './userPrefrence';
import productLevelFilters from './filter';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import pesimulator from './pesimulator';
import pesimulatorProduct from './pesimulatorProduct';

// project import
import snackbar from './snackbar';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  dashboard,
  snackbar,
  dateRange,
  pesimulator,
  pesimulatorProduct: persistReducer(
    {
      key: 'pesProduct',
      storage
    },
    pesimulatorProduct
  ),
  userPrefrence: persistReducer(
    {
      key: 'user',
      storage
    },
    userPrefrence
  ),
  productLevelFilters
});

export default reducers;
