import { getApi } from './common';

export const getFilters = (sourceName) => {
  return getApi(`price-elasticity/filters?source=${sourceName}`)
    .then((data) => data)
    .catch((error) => error);
};

export const getTooltips = () => {
  return getApi(`price-elasticity/tooltips`)
    .then((data) => data)
    .catch((error) => error);
};