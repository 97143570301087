import { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AdminPage = Loadable(lazy(() => import('pages/admin')));

//---------Defining privilage routes------------//
const adminRoute = [{
  path: '/adminPage',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/adminPage',
      element: <AdminPage />
    }
  ]
}]

export function getPrivilegedRoutes() {
  if (localStorage.getItem('user')) {
    const userAccessDetails = JSON.parse(localStorage.getItem('user') || "");
    switch (userAccessDetails.myrole[0]) {
      case 'FIN':
        console.log('FIN!!!');
        break;
      case 'SAL':
        console.log('SAL!!!');
        break;
      case 'MKT':
        console.log('MKT!!!');
        break;
      case 'KCA':
        //example : only KC Admin role we want to show admin route
        console.log('KCA!!!');
        return adminRoute;
      default:
        console.log('Default Case');
        break;
    }
    return [];
  } else {
    return [];
  }
}


