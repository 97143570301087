import { createSlice } from '@reduxjs/toolkit';

interface ProductLocator {
    level: string;
    pid: string;
    cat: string;
    rgn: string;
    scha: string;
    brd: string;
    subBrd: string;
}
const initialState = {
    pesProduct: <ProductLocator>{},
    pidDetailsQuery: <ProductLocator>{}
};

const pesimulatorProduct = createSlice({
    name: 'pesimulatorProduct',
    initialState,
    reducers: {
        setProductLocator(state, action) {
            state.pesProduct = action.payload;
        },
        setPidDetailsQuery(state, action) {
            state.pidDetailsQuery = action.payload;
        }
    }
});

export default pesimulatorProduct.reducer;
export const { setProductLocator, setPidDetailsQuery } = pesimulatorProduct.actions;
